html, body {
    height: 100%;
    margin: 0;
  }
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    min-width: 352px;
    transition: background-color 0.5s;
    overflow: hidden;
  }
  * {
    user-select: none;
  }
  .fail {
    animation: fail 0.5s ease-in-out;
    background: hsl(0, 64%, 61%) !important;
  }
  .next {
    animation: next 2s ease-in-out;
  }
  .unlock {
    animation: unlock 0.5s ease-in-out forwards;
  }
  .lock-game{
    z-index: 5 !important;
  }
  #label {
    text-transform: uppercase;
    font-size: 36px;
    height: 128px;
    color: rgba(255, 255, 255, 0.5);
  }
  #shackle {
    height: 128px;
    width: 92px;
    border: 48px solid;
    border-bottom-color: transparent !important;
    border-radius: 92px 92px 0 0;
    margin-bottom: -64px;
    opacity: 0.6;
    margin: 0 auto -64px auto;
  }
  #lock {
    height: 192px;
    width: 192px;
    border: 48px solid;
    border-radius: 50%;
    line-height: 192px;
    text-align: center;
    font-size: 128px;
    color: rgba(255, 255, 255, 0.5);
  }
  .dial {
    position: absolute;
    height: 288px;
    width: 288px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
  }
  #line {
    margin: 6px;
    height: 36px;
    width: 12px;
    border-radius: 4px;
    background: hsl(343, 80%, 54%);
  }
  #notch {
    margin: 10px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: hsl(48, 83%, 59%);
  }
  .appear-a {
    animation: appear-a 0.1s;
  }
  .appear-b {
    animation: appear-b 0.1s !important;
  }
  @keyframes appear-a {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes appear-b {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes fail {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(-2deg);
    }
    50% {
      transform: rotate(0);
    }
    75% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  @keyframes next {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    49.8% {
      opacity: 1;
    }
    49.9% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50.1% {
      opacity: 0;
      transform: translateX(100%);
    }
    50.2% {
      opacity: 1;
    }
    75% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes unlock {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-64px);
    }
  }
  