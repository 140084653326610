:root {
    --base: #FFFD20;
    --base-light: #fffc66;
    --base-dark: #e6e200;
    --shadow: #ccc900;
    --letter: black;
}
* {
    border: 0;
    margin: 0;
    box-sizing: border-box;
}
.alertContainer {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0%;
}
.content {
    position: relative;
    perspective: 65%;
}
.letter {
    position: absolute;
    top: 0;
    left: 5vw;
    width: 70vw;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15;
    border-radius: 2px;
    background: var(--letter);
    border: 1px solid var(--base);
}
.letter .body {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--base);
}
.letter .body .close {
    position: absolute;
    right: 5%;
    top: 0;
    font-size: 30px;
    font-family: sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.letter .body .message {
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    font-family: 'Great Vibes';

}
.envelope {
    position: relative;
    width: 80vw;
    height: 25vh;
    background: linear-gradient(#fffeb3 0.5px, var(--base-light) 0.5px);
    cursor: pointer;
}
.envelope::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 80vw;
    border-top: 35vw solid #fffeb3;
    border-left: 40vw solid transparent;
    border-right: 40vw solid transparent;
    box-sizing: border-box;
    z-index: 30;
    transform-origin: top;
}
.envelope::after {
    content: '';
    position: absolute;
    top: 0;
    left:0;
    width: 80vw;
    height: 25vh;
    z-index: 25;
    background: linear-gradient(30deg, var(--base-dark) 47%, var(--shadow) 50%, var(--base) 50%)
    40vw 12.5vh / 40vw 12.5vh no-repeat,
    linear-gradient(31deg, var(--base) 49%, var(--shadow) 50%, transparent 50%)
    0 0 / 40vw 12.6vh no-repeat,
    linear-gradient(150deg, var(--base) 50%, var(--shadow) 50%, var(--base-dark) 53%)
    0 12.5vh / 40vw 12.7vh no-repeat,
    linear-gradient(148.7deg, transparent 50%, var(--shadow) 50%, var(--base) 51%)
    40vw 0 / 40vw 12.8vh no-repeat;
}
