.diceContainer{
    width:100%;
    height:100%;
    position: absolute;
    top:40%;
    left: 30%;
    z-index: 2;
}

.cube {
    position: relative;
    width: 40vw;
    height: 40vw;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
}

.face {
    position: absolute;
    width: 40vw;
    height: 40vw;
    text-align: center;
    line-height: 40vw;
    font-size: 13vh;
    border: 5px solid white;
    background-color: var(--offBlack);
    font-family: "Monoton", sans-serif;
    backface-visibility: hidden;
    border-radius: 2.5vh;
}


.front {
    transform: translateZ(20vw);
}

.back {
    transform: rotateY(180deg) translateZ(20vw);
}

.left {
    transform: rotateY(-90deg) translateZ(20vw);

}

.right {
    transform: rotateY(90deg) translateZ(20vw);
}

.top {
    transform: rotateX(90deg) translateZ(20vw);

}

.bottom {
    transform: rotateX(-90deg) translateZ(20vw);

}

@keyframes rotateCube {
    0% {
        transform: translateZ(0) rotate3d(1, 0.7, 0, 0) rotate3d(0.7, 1, 0, 0) rotate3d(0, 0.7, 1, 0);
    }
    25% {
        transform: translateZ(0) rotate3d(1, 1, 0, 0.5turn) rotate3d(0, 1, 1, 0.25turn) rotate3d(1, 0, 0, 0.5turn);
    }
    50% {
        transform: translateZ(0) rotate3d(1, 1, 0, 1turn) rotate3d(0, 1, 1, 0.5turn) rotate3d(1, 0, 0, 1turn);
    }
    75% {
        transform: translateZ(0) rotate3d(1, 1, 0, 1.5turn) rotate3d(0, 1, 1, 0.75turn) rotate3d(1, 0, 0, 1.5turn);
    }
    100% {
        transform: translateZ(0) rotate3d(1, 1, 0, 2turn) rotate3d(0, 1, 1, 1turn) rotate3d(1, 0, 0, 2turn);
    }
}


@keyframes rotateDice {
    0% {
        transform: rotate3d(1, 0, 0, var(--current-rotate-x, 0deg)) rotate3d(0, 1, 0, var(--current-rotate-y, 0deg)) rotate3d(0, 0, 1, 0);
    }
    100% {
        transform: rotate3d(1, 0, 0, var(--target-rotate-x, 0deg)) rotate3d(0, 1, 0, var(--target-rotate-y, 0deg)) rotate3d(0, 0, 1, 0);
    }
}

@keyframes fadeOut{
    0%{
        opacity: 100%;
    }
    100%{
        opacity: 0%;
    }
}

.cube.animate {
    animation: rotateCube 2s linear infinite;
}

.cube.stop {
    animation: none;
}

.face.rotate {
    animation: rotateDice 1s ease forwards;
}
