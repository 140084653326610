@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Bebas+Neue);
@import url(https://fonts.googleapis.com/css?family=Monoton);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Germania+One);
@import url(https://fonts.googleapis.com/css?family=Tilt+Neon);
@import url(https://fonts.googleapis.com/css?family=Neonderthaw);
@import url(https://fonts.googleapis.com/css?family=Train+One);
@import url(https://fonts.googleapis.com/css?family=Abel);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Bebas+Neue);
@import url(https://fonts.googleapis.com/css?family=Monoton);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Pacifico);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://use.typekit.net/sml3dkn.css);
html, body{
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
  overflow: hidden;
  background: black;
}

*{
  font-weight: 550 !important;
  text-transform: uppercase;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.diceContainer{
    width:100%;
    height:100%;
    position: absolute;
    top:40%;
    left: 30%;
    z-index: 2;
}

.cube {
    position: relative;
    width: 40vw;
    height: 40vw;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
}

.face {
    position: absolute;
    width: 40vw;
    height: 40vw;
    text-align: center;
    line-height: 40vw;
    font-size: 13vh;
    border: 5px solid white;
    background-color: var(--offBlack);
    font-family: "Monoton", sans-serif;
    backface-visibility: hidden;
    border-radius: 2.5vh;
}


.front {
    transform: translateZ(20vw);
}

.back {
    transform: rotateY(180deg) translateZ(20vw);
}

.left {
    transform: rotateY(-90deg) translateZ(20vw);

}

.right {
    transform: rotateY(90deg) translateZ(20vw);
}

.top {
    transform: rotateX(90deg) translateZ(20vw);

}

.bottom {
    transform: rotateX(-90deg) translateZ(20vw);

}

@keyframes rotateCube {
    0% {
        transform: translateZ(0) rotate3d(1, 0.7, 0, 0) rotate3d(0.7, 1, 0, 0) rotate3d(0, 0.7, 1, 0);
    }
    25% {
        transform: translateZ(0) rotate3d(1, 1, 0, 0.5turn) rotate3d(0, 1, 1, 0.25turn) rotate3d(1, 0, 0, 0.5turn);
    }
    50% {
        transform: translateZ(0) rotate3d(1, 1, 0, 1turn) rotate3d(0, 1, 1, 0.5turn) rotate3d(1, 0, 0, 1turn);
    }
    75% {
        transform: translateZ(0) rotate3d(1, 1, 0, 1.5turn) rotate3d(0, 1, 1, 0.75turn) rotate3d(1, 0, 0, 1.5turn);
    }
    100% {
        transform: translateZ(0) rotate3d(1, 1, 0, 2turn) rotate3d(0, 1, 1, 1turn) rotate3d(1, 0, 0, 2turn);
    }
}


@keyframes rotateDice {
    0% {
        transform: rotate3d(1, 0, 0, var(--current-rotate-x, 0deg)) rotate3d(0, 1, 0, var(--current-rotate-y, 0deg)) rotate3d(0, 0, 1, 0);
    }
    100% {
        transform: rotate3d(1, 0, 0, var(--target-rotate-x, 0deg)) rotate3d(0, 1, 0, var(--target-rotate-y, 0deg)) rotate3d(0, 0, 1, 0);
    }
}

@keyframes fadeOut{
    0%{
        opacity: 100%;
    }
    100%{
        opacity: 0%;
    }
}

.cube.animate {
    animation: rotateCube 2s linear infinite;
}

.cube.stop {
    animation: none;
}

.face.rotate {
    animation: rotateDice 1s ease forwards;
}

.splashContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: None;
  color: white;
  transition: opacity 0.5s ease-in-out;
  will-change: top, left, width, height;
  position: relative;
  z-index: 0;
}

.resultPage {
  z-index: 5;
  height: 93%;
  width: 100vw;
  display: flex;
  background-color: black;
  position: absolute;
  top: 7%;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drEvent {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.zoomTile {
  animation: zoomToFill 0.75s forwards;
  z-index: 5;
  will-change: top, left, width, height;
}

@keyframes zoomToFill {
  100% {
    top: 0%;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 5;
  }
}

@keyframes fadeinBackground {
  100% {
    background-color: var(--offBlack);
  }
}

.miniGameCard {
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 5;
}

.resultsGraph {
  width: 100%;
  height: 80%;
  justify-content: center;
  padding: auto;
  overflow-y: auto;
}

.resultsList {
  width: 100%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 100%));
  grid-auto-rows: 10%;
  justify-content: center;
  grid-row-gap: 5%;
  row-gap: 5%;
  margin-top: 5%;
  padding: auto;
  overflow-y: auto;
  z-index: 5;
  background-color: black;
}

.progress-bar {
  width: 100%;
  height: 15%;
  background-color: var(--offBlack);
}

.progress-bar__fill {
  height: 100%;
  background-color: black;
  transition: width 1s ease-out;
  z-index: 0;
  border-radius: 10px;
}

.progress-bar__text {
  margin-top: 8px;
  margin-left: 5%;
  text-align: left;
  opacity: 0;
  font-family: (var(--contentFont));
  font-size: 2vh;
}

.inputResultText {
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  opacity: 0;
  font-family: (var(--contentFont));
  display: flex;
  font-size: 2.75vh;
  border-bottom: #151515 solid 5px;
  position: relative;
  z-index: 2;
  align-items: center;
  white-space: nowrap;
}

.waitingGifContainer {
  display: flex;
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;
}

.flipped {
  transform: rotateY(180deg);
}

.titleContainer {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 6vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  align-items: center;
  z-index: 2;
  padding-top: 2.5%;
}

.eventIcon {
  width: 25vh;
  margin: -5%;
  height: 25vh;
  opacity: 0;
  animation: zoomText 0.2s linear 0.4s 1 forwards;
}

.rulesContainer {
  position: relative;
}

.waveClass {
  position: absolute;
  bottom: 0;
  height: 0%;
  background: none;
  z-index: 1;
  display: flex;
  animation: fillUpScreen 1.25s ease-out 0s 1 forwards;
  will-change: height;
}

.quickWaveClass {
  position: absolute;
  bottom: 0;
  height: 0%;
  background: none;
  z-index: 1;
  display: flex;
  animation: fillUpScreen 1.25s ease-out 0s 1 forwards;
  will-change: height;
}

.movieEmojiWrapper {
  height: 20%;
  width: 100%;
  position: relative;
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  grid-column-gap: 5%;
  column-gap: 5%;
  font-size: 8vh;
}

.movieChalPrompt {
  height: 25%;
  width: 90%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  color: black;
  align-content: center;
  white-space: pre-line;
  opacity: 0;
  animation: zoomText 0.2s linear 0.2s 1 forwards;
  z-index: 2;
  margin: auto;
  font-size: 3.05vh;
}

.movieChalButtons {
  width: 90%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 5%;
  column-gap: 5%;
}

.moviesChalButton {
  width: 45%;
  height: 55%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  border-radius: 2.5vw;
  color: var(--accentColor);
}

.rulesTitleContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.2s infinite linear;
  padding-top: 15%;
  white-space: pre-line;
}
.mineLogo {
  width: auto;
  height: 60%;
  align-self: center;
  justify-self: center;
}
.mineFlipCard {
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 5vh;
  align-items: center;
  justify-content: center;
  display: flex;
  perspective: 1000px;
  border-radius: 6px;
}

.mineFlipCard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.mineFlipCard.flipped .mineFlipCard-inner {
  transform: rotateY(180deg);
}

.mineFlipCard-front,
.mineFlipCard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: opacity 0.15s ease-in-out;
}

.mineFlipCard-back {
  transform: rotateY(180deg);
}

.minesCounterTitle {
  justify-self: left;
  height: 30%;
  align-content: center;
  margin-right: 0.5em;
  text-transform: none !important;
}

.minesCounter {
  border: 5px solid #151515;
  border-radius: 5%;
  align-content: center;
  width: 2em;
  height: 2em;
  font-size: 4.5vh;
  margin-left: 0.5em;
}

.minesCounterContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 2.5vh;
  white-space: pre-line;
}
.heroTitleContainer {
  background-color: none;
  position: relative;
  height: 15%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 6vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 3vh;
  white-space: pre-line;
}

.minesTitleContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 2.5vh;
  white-space: pre-line;
}

.mineSubtitleContainer {
  background-color: none;
  position: relative;
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 2.75vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  white-space: pre-line;
}

.rouletteOdometer {
  background-color: none;
  position: relative;
  height: 15%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  white-space: pre-line;
  transition: opacity 0.3s linear;
}
.odometer {
  overflow: hidden;
  height: 2em;
  width: 2em;
  position: relative;
  border-radius: 1vh;
  border: 5px solid #151515;
}

.odNumber {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  font-weight: 700;
  font-size: 5.25vh;
}

.odNumber.scroll {
  animation: scrollAnimation 0.5s ease-out forwards, glowText 0.75s linear;
}

.odNumber.glow {
}

@keyframes glowText {
  0% {
    color: black;
  }
  20% {
    color: var(--baseColor);
  }
  100% {
    color: black;
  }
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
}

.odNumber-item {
  height: 2em; /* Match this to the .odometer height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.roulettePrompt {
  background-color: none;
  position: relative;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 3.5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding-top: 2.5vh;
  white-space: pre-line;
}

.rouletteContent {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: space-evenly;
}

.rouletteColumn {
  display: flex;
  width: 30%;
  height: 100%;
  flex-direction: column;
  border-top-left-radius: 50px; /* Rounded top left */
  border-top-right-radius: 50px; /* Rounded top right */
  border-bottom-left-radius: 0; /* Straight bottom left */
  border-bottom-right-radius: 0; /* Straight bottom right */
  align-items: center;
}

.rouletteOption {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 5px solid white;
  box-shadow: 0 5px 5px black;
  margin-bottom: 10%;
}

.rouletteColumn.dull {
  filter: grayscale(100%) !important;
}

.rouletteIcon {
  width: 60%;
  height: auto;
}
.loser {
  color: white;
  font-size: 3vh;
  text-shadow: 0 0 10px var(--accentColor);
}

.loserAlert {
  width: 70%;
  height: auto;
  font-family: var(--subTitleFont), sans-serif;
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  border-radius: 2.5vw;
  text-align: center !important;
  padding: 2%;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 30%;
  left: 15%;
  z-index: 500;
  white-space: pre-line;
  display: flex;
  animation: fadeIn 0.5s linear forwards;
}

.wheel {
  position: absolute;
  width: 50vh;
  height: 50vh;
  top: calc(50% - 49vh / 2);
  left: calc(50% - 50vh / 2);
  border-radius: 50%;
  border: 8px solid rgb(255, 255, 255);
  margin: -1vh; /* 8px push border */
  overflow: hidden;
  transition: transform 5s ease;
}

.wheel:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  z-index: 1;
}

.wheel .inner {
  width: 50%;
  height: 50%;
  transition: transform 5s ease;
}

.wheel .inner .slice {
  position: absolute;
  width: 0;
  height: 0;
  left: 17vh;
  top: -1.5vh;
  border-width: 25.5vh 7.25vh 0 7vh;
  border-style: solid;
  transform-origin: 50% 100%;
}

.wheel .inner .slice .prize {
  position: relative;
  display: block;
  transform: rotateZ(90deg);
  left: 1vh;
  text-align: center;
  margin-top: -23vh;
  margin-left: -2vh;
}

.wheelWrapper {
  height: 50vh;
  width: 100%;
}
.wheelTitleContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 2.5vh;
  white-space: pre-line;
}

.prizeIcon {
  width: 10vh;
  height: 10vh;
  transform: rotate(-90deg) translateY(-3vh);
}

.wheelArrow {
  width: 4%;
  height: 10%;
  display: flex;
  position: absolute;
  z-index: 100;
  top: 20%;
  left: 48%;
  color: var(--baseColor);
  justify-content: center;
  font-size: 5vh;
}
/* Slices colors and rotations */
.wheel .inner .slice:nth-child(1) {
  transform: rotate(-30deg);
}
.wheel .inner .slice:nth-child(2) {
  transform: rotate(-60deg);
}
.wheel .inner .slice:nth-child(3) {
  transform: rotate(-90deg);
}
.wheel .inner .slice:nth-child(4) {
  transform: rotate(-120deg);
}
.wheel .inner .slice:nth-child(5) {
  transform: rotate(-150deg);
}
.wheel .inner .slice:nth-child(6) {
  transform: rotate(-180deg);
}
.wheel .inner .slice:nth-child(7) {
  transform: rotate(-210deg);
}
.wheel .inner .slice:nth-child(8) {
  transform: rotate(-240deg);
}
.wheel .inner .slice:nth-child(9) {
  transform: rotate(-270deg);
}
.wheel .inner .slice:nth-child(10) {
  transform: rotate(-300deg);
}
.wheel .inner .slice:nth-child(11) {
  transform: rotate(-330deg);
}
.wheel .inner .slice:nth-child(12) {
  transform: rotate(-360deg);
}

.wheel-outer {
  position: absolute;
  width: 50vh;
  height: 50vh;
  top: calc(50% - 50vh / 2);
  left: calc(50% - 50vh / 2);
  border-radius: 50%;
}

#svg-dotted {
  position: absolute;
  width: 540px;
  height: 540px;
  top: calc(50% - 540px / 2);
  left: calc(50% - 540px / 2);
}

#svg-dotted #circle-dotted {
  fill: transparent;
  stroke: rgb(200, 200, 200);
  stroke-width: 4;
  stroke-dasharray: 0.1 23;
  stroke-dashoffset: 19.5;
  stroke-linecap: round;
}

.wheelButton {
  position: absolute;
  width: 10vh;
  height: 10vh;
  top: calc(50% - 5vh);
  left: calc(50% - 6vh);
  z-index: 10;
  transition: transform 5s ease;
}

.waitingTitle {
  height: 15%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  margin-top: 5vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.resultsTitle {
  height: 15%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 3.5vh;
  font-family: var(--subTitleFont);
  border-bottom: #151515 solid 5px;
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.quipInput {
  width: 80%;
  height: 6vh;
  font-family: var(--contentFont);
  font-size: 2.5vh;
  border-radius: 2.5vw;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.quipVoteButton {
  width: 0;
  height: 0;
  font-family: var(--titleFont);
  font-size: 0;
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  color: var(--accentColor);
  text-shadow: 0 0 5px var(--accentColor);
  margin: 2vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  animation: appear 0.1s linear 0s 1 forwards;
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
}

.quipVoteResult {
  position: relative;
  opacity: 1;
  width: 75%;
  height: 15%;
  font-size: 2.5vh;
  font-family: var(--titleFont);
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  color: var(--accentColor);
  text-shadow: 0 0 5px var(--accentColor);
  margin: 2vh;
  border-radius: 2.5vw;
  z-index: 1;
  align-self: center;
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
}

.quipResultText {
  position: absolute;
  top: 50%; /* Center the text vertically */
  left: 50%; /* Center the text horizontally */
  transform: translate(-50%, -50%); /* Center the text precisely */
  opacity: 0.5;
}

.quipVoteOverlay {
  position: relative; /* Position the overlay relative to the quipVoteResult button */
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  background-color: none;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.quipVoteButton.selected {
  background-color: white;
  border-color: black;
  color: black;
}

.quipNameTag {
  color: black;
  font-size: 2.75vh;
  font-family: var(--titleFont);
  margin-bottom: 0;
}

.quipNameTag.loser {
  color: red;
}

.quipVoteResult.loser {
  border: 6px solid red;
}

.quipTitle {
  height: 25%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4.5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  padding-top: 5%;
}

.quipWaiting.center {
  align-self: center;
}
.quipPrompt {
  height: 20%;
  width: 80%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  font-style: italic;
  color: black;
  font-size: 2.75vh;
  font-family: var(--titleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.quipSubtitle {
  height: 15%;
  width: 80%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 2.5vh;
  font-family: var(--titleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

@keyframes turnOn {
  0% {
    color: #151515;
  }
  90% {
    color: white;
  }
  91% {
    color: #151515;
  }
  93% {
    color: white;
  }
  100% {
    color: white;
  }
}

@keyframes flickerOn {
  0% {
    color: #151515;
  }
  15% {
    color: white;
  }
  16% {
    color: #151515;
  }
  17% {
    color: white;
  }
  60% {
    color: white;
  }
  61% {
    color: #151515;
  }
  65% {
    color: white;
  }
  67% {
    color: #151515;
  }
  70% {
    color: white;
  }
  100% {
    color: white;
  }
}

@keyframes flicker {
  0% {
    color: white;
  }
  70% {
    color: white;
  }
  72% {
    color: #151515;
  }
  77% {
    color: white;
  }
  78% {
    color: #151515;
  }
  79% {
    color: white;
  }
  100% {
    color: white;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.votePrompt {
  height: 20%;
  width: 90%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.subTitleContainer {
  height: 10%;
  width: 100%;
  display: flex;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: flex-start;
  justify-content: center;
  align-items: flex-start;
}

.leaderboardNavBtn {
  width: 60%;
  height: 6%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  position: absolute;
  bottom: 10%;
}

.leaderboardNavBtn:active {
  background-color: white;
  color: black;
}

.pokerBtnContainer {
  height: 20vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.pokerVoteBtn {
  width: 70%;
  height: 40%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  color: var(--accentColor);
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pokerFlipCard {
  height: 30vh;
  width: 20vh;
  display: flex;
  perspective: 1000px;
  border-radius: 6px;
  opacity: 0;
}

.pokerFlipCard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.pokerFlipCard.flipped .pokerFlipCard-inner {
  transform: rotateY(180deg);
}

.pokerFlipCard-front,
.pokerFlipCard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.pokerFlipCard-back {
  transform: rotateY(180deg);
}

.pokerContainer {
  height: 50vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.pokerCardImage {
  height: auto;
  width: 100%;
}

.pokerPrompt {
  height: 20%;
  width: 90%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  font-family: var(--titleFont);
  align-items: center;
  padding-top: 10vh;
  opacity: 0;
  animation: zoomText 0.2s linear 0.5s 1 forwards;
}

.reactionBtn {
  width: 80%;
  height: 25%;
  font-family: var(--titleFont);
  font-size: 4vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  margin: 1vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
}

.waveSplashArt {
  animation: fadeOut 0.25s linear 1.3s 1 forwards;
}

.splashNavBtn {
  transition: all 0.5s ease-in-out;
  width: 60%;
  height: 12.5%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  text-shadow: 0 0 10px var(--baseColor);
  margin: 3vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  animation: animate-pulse 3s linear infinite;
}

.splashNavBtn.roulette {
  position: absolute;
  top: 65vh;
}

.quipWaitingWrapper {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quipSendBtn {
  width: 30vw;
  height: 7vh;
  font-family: var(--titleFont);
  font-size: 2vh;
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  color: var(--accentColor);
  text-shadow: 0 0 10px var(--accentColor);
  margin-top: 1vh;
  border-radius: 40%/90%;
  z-index: 2;
  left: 25vw;
  animation: animate-pulse-highlight 3s linear infinite;
  position: relative;
}
.splashNavBtn:active {
  background-color: white;
  color: black;
}
.lifeRaftButton {
  width: 80%;
  height: 15%;
  font-family: var(--subTitleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  color: white;
  margin-top: 2vh;
  margin-bottom: 2vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  text-align: center;
}

.shakeElement {
  animation: tilt-shaking 0.2s 1 linear;
}

.choiceChalPrompt {
  height: 30%;
  width: 100%;
  display: flex;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding: 5%;
}

.draggingContainer {
  list-style: none;
  padding-left: 0;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.sortingChalPrompt {
  height: 15%;
  width: 100%;
  display: flex;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding: 2%;
}

.heroString {
  height: 100%;
  width: 4px;
  background: white;
  z-index: 10;
  position: absolute; /* Allow precise alignment */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset by half the width for centering */
}

.heroButton.hidden {
  opacity: 0;
  width: 20vh;
  height: 20vh;
  font-size: 10vh;
}

.heroButton {
  width: 13vh; /* Square size for consistency */
  height: 13vh;
  position: absolute;
  border-radius: 50%;
  top: 0;
  background-color: transparent;
  left: 50%; /* Align horizontally with the string */
  transform: translateX(-50%); /* Offset by half the width for centering */

  font-size: 6.5vh;
  color: white; /* Set text color for visibility */
  text-align: center;
  z-index: 20;
  animation: heroSlide 1.25s linear forwards;
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
  transition: transform 0.1s ease, opacity 0.2s ease, font-size 0.15s ease,
    width 0.15s, height 0.15s ease; /* Older versions of Safari */ /* IE 10 */
  display: flex; /* Safari 6.1+ */
  -ms-align-items: center; /* IE 10 */
  align-items: center; /* Safari 6.1+ */
  -ms-justify-content: center; /* IE 10 */
  justify-content: center;
}

@keyframes heroSlide {
  0% {
    top: 0%;
  }
  100% {
    top: 100%;
  }
}

.heroContainer {
  height: 85%;
  width: 100%;
  color: black;
  display: flex;
  justify-content: space-evenly;
  position: relative; /* Allow child elements to be positioned absolutely */
  overflow: hidden; /* Hide overflowing elements */
}

.heroCol {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure buttons position correctly within each column */
}

.heroChalBanner {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: var(--titleFont);
  font-size: 6vh;
  padding-top: 5%;
  text-align: center;
}

.colorChalCircleContainer {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.colorChalBanner {
  width: 100%;
  height: 15%;
  justify-content: center;
  align-content: center;
  color: black;
  font-family: var(--titleFont);
  font-size: 6vh;
  padding-top: 5%;
}

.colorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2vh;
}

.colorLabel {
  color: black;
  font-size: 4vh;
  font-family: var(--contentFont);
  height: 10%;
  width: 100%;
}

.colorChalCircle {
  height: 20vh;
  width: 20vh;
  border-radius: 50%;
}

.resultsPrizeContainer {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 2vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
}

.pickContainer {
  width: 100%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 100%));
  grid-auto-rows: 25%;
  justify-content: center;
  grid-row-gap: 5%;
  row-gap: 5%;
  margin-top: 10%;
  padding: auto;
}

.firstPage {
  opacity: 0;
  animation: fadeIn 0.3s linear 2s 1 forwards;
}

.voteContent {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 50%));
  grid-auto-rows: 11%;
  justify-content: center;
  grid-row-gap: 2%;
  row-gap: 2%;
  margin-top: 2%;
  padding: auto;
}

.twoChoiceContent {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 50%));
  grid-auto-rows: 35%;
  justify-content: center;
  grid-row-gap: 2%;
  row-gap: 2%;
  margin-top: 15%;
  padding: auto;
}

.beautifulSortingContainer {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.spinBottleImage {
  height: 45%;
  width: auto;
  transition: transform 5s ease-in-out;
  margin-bottom: 5vh;
}

.sortingContentWrapper {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sortingChalLabel {
  display: flex;
  width: 100%;
  height: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
  font-family: var(--subTitleFont);
  color: black;
}

.sortingChalOption {
  display: flex;
  width: 100%;
  height: 6vh;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 2.5vh;
  font-family: var(--contentFont);
  border: 5px solid white;
  background-color: var(--offBlack);
  color: white;
  border-radius: 2.5vw;
}

.sortScaleA {
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3.25vh;
  font-family: var(--subTitleFont);
  color: black;
  text-decoration: underline;
  padding-top: 3%;
}
.sortScaleB {
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3.25vh;
  font-family: var(--subTitleFont);
  color: black;
  text-decoration: underline;
  padding-bottom: 3%;
}

.choiceChalContent {
  width: 100%;
  height: 60%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 90%));
  grid-auto-rows: 25%;
  justify-content: center;
  grid-row-gap: 5%;
  row-gap: 5%;
  margin-top: 5%;
  padding: auto;
}

.choiceChalOption {
  display: flex;
  width: 80%;
  height: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  border: 5px solid black;
  background-color: var(--offBlack);
  color: white;
  border-radius: 2.5vw;
}

.voteOption {
  display: flex;
  width: 90%;
  height: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 2.75vh;
  font-family: var(--contentFont);
  border: 5px solid #252525;
  background-color: var(--offBlack);
  color: white;
  border-radius: 2.5vw;
}
.randomVoteButton {
  grid-column: span 2;
  width: 60%;
  color: white;
  border-radius: 15%/90%;
}

.optionRow {
  display: flex;
  width: 60%;
  height: 80%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  border: 3px solid black;
  color: black;
}

.optionRow.highlighted {
  border-color: white;
  box-shadow: 0 0 10px white;
  color: white;
}

.optionRow.selected {
  box-shadow: 0 0 10px #ffff66;
  border-color: #ffff66;
  color: #ffff66;
}

.quizContent {
  width: 100vw;
  height: 40vh;
}

.quizAnswerBig {
  font-size: 2.5vh;
  width: 100%;
  height: auto;
  text-align: left;
}

.quizAnswerMini {
  font-size: 1.75vh;
  width: 100%;
  height: auto;
  text-align: left;
}

.quizAnswersBig {
  width: 100%;
  height: 60%;
  display: grid;
  grid-template-columns: 80%;
  grid-auto-rows: 10%;
  justify-content: center;
  grid-row-gap: 8%;
  row-gap: 8%;
  overflow-y: hidden;
  padding-top: 5%;
}

.quizAnswersMini {
  width: 100%;
  height: 60%;
  display: grid;
  grid-template-columns: 44% 45%;
  grid-auto-rows: 10%;
  justify-content: center;
  grid-row-gap: 5%;
  row-gap: 5%;
  overflow-y: hidden;
  padding-top: 5%;
}

.quizRulesText {
  width: 90%;
  height: 40%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  color: black;
  white-space: pre-line;
  opacity: 0;
  animation: zoomText 0.2s linear 0.2s 1 forwards;
  z-index: 2;
  font-size: 3vh;
  margin: auto;
}

.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: var(--baseColor);
}
.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}

.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 1vh;
  left: 0;
  height: 3vh;
  width: 3vh;
  background-color: white;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #151515;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
.checkbox.style-c .checkbox__body {
  color: #151515;
  padding-left: 1vw;
  flex-wrap: nowrap;
}

.rulesText {
  width: 90%;
  height: 75%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  color: black;
  align-content: center;
  white-space: pre-line;
  opacity: 0;
  font-size: 2vh;
  animation: zoomText 0.3s linear 0.2s 1 forwards;
  z-index: 2;
  margin: auto;
}

.horizontalPrompt {
  width: 90%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: black;
}

.rotatedText {
  font-family: var(--subTitleFont);
  font-size: 5.5vh;
  white-space: pre-line;
}

.coinIcon {
  width: 12vh;
  height: 12vh;
}

.coinTextTop {
  text-transform: none !important;
}

.coinTextBot {
  text-transform: none !important;
}

.coinWrapper {
  perspective: 800px;
  -webkit-perspective: 800px;
  height: 60%;
  display: flex;
  align-items: center;
}

#coin {
  position: relative;
  margin: 0 auto;
  color: black;
  width: 30vh;
  height: 30vh;
  font-size: 5.5vh;
}
.coinContainer {
  width: 100%;
  height: 100%;
  font-family: var(--splashTitleFont);
  text-transform: none !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 1vh;
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 50%;
}
.side-a {
  border: 3px solid green;
  color: #151515;
  background: radial-gradient(circle, lightgreen, #99ffcc);
}
.side-b {
  color: white;
  background: radial-gradient(circle, #252525, black);
  border: 3px solid #252525;
}

#coin {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.side-a {
  z-index: 100;
}
.side-b {
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
}

#coin.heads {
  animation: flipHeads 4s ease-out forwards;
}
#coin.tails {
  animation: flipTails 4s ease-out forwards;
}
@keyframes flipHeads {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(2160deg);
  }
}

@keyframes flipTails {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(2340deg);
  }
}

.luckyCoinNav {
  width: 40%;
  height: 90%;
  font-family: var(--titleFont);
  font-size: 2.75vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  margin: 3vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  animation: animate-pulse-highlight 3s linear infinite;
}

.luckyCoinNavContainer {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
}

.chanceContent {
  width: 90%;
  height: 50%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  color: black;
}
.chancePicker {
  width: 100%;
  height: 30%;
  margin-bottom: 5%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 4vh;
}

.chanceResult {
  width: 40vw;
  height: 20vh;
  font-family: var(--subTitleFont);
  font-size: 3.5vh;
  border: 5px solid white;
  border-radius: 2.5vw;
  display: flex;
  flex-direction: column;
  text-align: center !important;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.chanceBtn {
  width: 40vw;
  height: 20vh;
  font-family: var(--subTitleFont);
  font-size: 3.5vh;
  animation: animate-pulse-white 2s linear infinite;
  background-color: var(--offBlack);
  border: 5px solid black;
  color: black;
  border-radius: 2.5vw;
  display: flex;
  text-align: center !important;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.chanceBtn span {
  /* Styles for the text within the chanceBtn */
  color: white;
  opacity: 0;
  animation: fadeAndMove 0.1s linear infinite;
}

@keyframes fadeAndMove {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  50% {
    opacity: 1;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(110%);
  }
}

.promptList {
  width: 100%;
  height: 80%;
  margin-top: 5%;
}

.promptListItem {
  width: 100%;
  height: 10%;
  color: black;
  font-size: 2.75vh;
}

.promptRulesText {
  width: 90%;
  height: 40%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  z-index: 2;
  white-space: pre-line;
  color: black;
}

.promptChalText {
  width: 90%;
  height: 30%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-style: italic;
  color: white;
}

.timedChalText {
  width: 90%;
  height: 35%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 12vh;
  color: black;
}

.resultText {
  width: 90%;
  height: 60%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
}

.chalTitleContainer {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 7vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  align-items: center;
  padding-top: 2.5vh;
}
.leaderboardPlayer {
  font-size: 3vh;
  display: flex;
  width: 100%;
  border-bottom: #151515 solid 5px;
}

.playerName {
  text-shadow: 1px 1px 5px black;
}

.leaderboardPlayerName {
  margin-left: 5vw;
}

.leaderboardPlayer span {
  position: absolute;
  font-size: 3vh;
  right: 10px;
  color: var(--accentColor);
}

.leaderboardTitle {
  height: 15%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 9vh;
  opacity: 0;
  font-family: var(--displayFont);
  text-transform: none !important;
  animation: reveal 1s 0.5s forwards;
}
.leaderboardPlayers {
  margin-top: 5vh;
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: (var(--contentFont));
  overflow-y: auto;
}
.leaderboardDisclaimer {
  font-family: (var(--contentFont));
  font-size: 2vh;
  font-style: italic;
  height: 20%;
}

.responseChalContent {
  width: 90%;
  height: 30%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.fullscreenChalContent {
  width: 95%;
  height: 75%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chalInput {
  width: 70%;
  height: 10%;
  margin: 3vh;
  font-family: var(--contentFont);
  font-size: 3vh;
  padding: 1%;
}

.rules {
  font-size: 2.9vh;
  margin-top: 8vh;
  padding: 1%;
}

.ruleEmoji {
  font-size: 12vh;
}

.prompt {
  font-size: 4vh;
}

.wordleBanner {
  width: 100%;
  height: 15%;
  justify-content: center;
  align-content: center;
  color: black;
  font-family: var(--titleFont);
  font-size: 6vh;
  padding-top: 5%;
}

.wordleLetter {
  border: 3px solid black;
  width: 100%;
  height: 100%;
  border-radius: 2.5vw;
  color: black;
  font-size: 4.5vh;
  transition: border-color 0.75s ease, color 0.75s ease,
    background-color 0.75s ease;
  background-color: none;
}

.wordleGrid {
  width: 100%;
  height: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-auto-rows: 17%;
  grid-auto-columns: 13%;
  grid-gap: 3%;
  grid-template-areas:
    "w00 w01 w02 w03"
    "w10 w11 w12 w13"
    "w20 w21 w22 w23"
    "w30 w31 w32 w33"
    "w40 w41 w42 w43";
}
.wordleKey {
  background-color: #151515;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  font-size: 2.5vh;
  width: 10%;
  height: 100%;
  padding-top: 2.5vh;
  border-radius: 2.5vw;
  margin: 0.25%;
}
.keyboardRow {
  display: flex;
  justify-content: center;
}
.wordleKeyboard {
  width: 100%;
  height: 30%;
  display: grid;
}

@keyframes pulseText {
  0% {
    text-shadow: 0 0 5px white;
  }
  50% {
    text-shadow: 0 0 8px white;
  }
  100% {
    text-shadow: 0 0 5px white;
  }
}

@keyframes zoomText {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  40% {
    opacity: 0.75;
    transform: scale(1.05);
  }
  80% {
    opacity: 1;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drink-shaking {
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes driftAndFade0 {
  0% {
    transform: translateY(0vh) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: translateY(-10vh) rotate(-5deg);
  }
  50% {
    transform: translateY(-20vh) rotate(5deg);
  }
  75% {
    transform: translateY(-30vh) rotate(-3deg);
  }
  100% {
    transform: translateY(-40vh) rotate(0deg);
    opacity: 0;
  }
}
@keyframes driftAndFade1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(3vh, -5vh);
  }
}
@keyframes driftAndFade2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-2vh, -5vh);
  }
}
@keyframes driftAndFade3 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(1vh, -5vh);
  }
}

@keyframes fillUpScreen {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes emptyDownScreen {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    height: 0;
    width: 0;
    font-size: 0;
  }
  100% {
    opacity: 1;
    width: 75%;
    height: 15%;
    font-size: 2.5vh;
  }
}

@keyframes flashName {
  0% {
    color: white;
  }
  50% {
    color: var(--turnPlayerColor);
  }
  100% {
    color: white;
  }
}

@keyframes float {
  from {
    transform: rotate(0deg) translate3d(2px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate3d(2px, 0, 0) rotate(-360deg);
  }
}

@keyframes bob1 {
  from {
    transform: rotate(0deg) translate3d(5px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate3d(5px, 0, 0) rotate(-360deg);
  }
}

@keyframes bob2 {
  from {
    transform: rotate(0deg) translate3d(3px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(-360deg) translate3d(3px, 0, 0) rotate(360deg);
  }
}

@keyframes bob3 {
  from {
    transform: rotate(0deg) translate3d(1px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate3d(1px, 0, 0) rotate(-360deg);
  }
}

/* Selected prize animation */
.prize.selected {
  color: white;
}

html, body {
    height: 100%;
    margin: 0;
  }
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    min-width: 352px;
    transition: background-color 0.5s;
    overflow: hidden;
  }
  * {
    -webkit-user-select: none;
            user-select: none;
  }
  .fail {
    animation: fail 0.5s ease-in-out;
    background: hsl(0, 64%, 61%) !important;
  }
  .next {
    animation: next 2s ease-in-out;
  }
  .unlock {
    animation: unlock 0.5s ease-in-out forwards;
  }
  .lock-game{
    z-index: 5 !important;
  }
  #label {
    text-transform: uppercase;
    font-size: 36px;
    height: 128px;
    color: rgba(255, 255, 255, 0.5);
  }
  #shackle {
    height: 128px;
    width: 92px;
    border: 48px solid;
    border-bottom-color: transparent !important;
    border-radius: 92px 92px 0 0;
    margin-bottom: -64px;
    opacity: 0.6;
    margin: 0 auto -64px auto;
  }
  #lock {
    height: 192px;
    width: 192px;
    border: 48px solid;
    border-radius: 50%;
    line-height: 192px;
    text-align: center;
    font-size: 128px;
    color: rgba(255, 255, 255, 0.5);
  }
  .dial {
    position: absolute;
    height: 288px;
    width: 288px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
  }
  #line {
    margin: 6px;
    height: 36px;
    width: 12px;
    border-radius: 4px;
    background: hsl(343, 80%, 54%);
  }
  #notch {
    margin: 10px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: hsl(48, 83%, 59%);
  }
  .appear-a {
    animation: appear-a 0.1s;
  }
  .appear-b {
    animation: appear-b 0.1s !important;
  }
  @keyframes appear-a {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes appear-b {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes fail {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(-2deg);
    }
    50% {
      transform: rotate(0);
    }
    75% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  @keyframes next {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    49.8% {
      opacity: 1;
    }
    49.9% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50.1% {
      opacity: 0;
      transform: translateX(100%);
    }
    50.2% {
      opacity: 1;
    }
    75% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes unlock {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-64px);
    }
  }
  
:root {
    --base: #FFFD20;
    --base-light: #fffc66;
    --base-dark: #e6e200;
    --shadow: #ccc900;
    --letter: black;
}
* {
    border: 0;
    margin: 0;
    box-sizing: border-box;
}
.alertContainer {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0%;
}
.content {
    position: relative;
    perspective: 65%;
}
.letter {
    position: absolute;
    top: 0;
    left: 5vw;
    width: 70vw;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15;
    border-radius: 2px;
    background: var(--letter);
    border: 1px solid var(--base);
}
.letter .body {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--base);
}
.letter .body .close {
    position: absolute;
    right: 5%;
    top: 0;
    font-size: 30px;
    font-family: sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}
.letter .body .message {
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    font-family: 'Great Vibes';

}
.envelope {
    position: relative;
    width: 80vw;
    height: 25vh;
    background: linear-gradient(#fffeb3 0.5px, var(--base-light) 0.5px);
    cursor: pointer;
}
.envelope::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 80vw;
    border-top: 35vw solid #fffeb3;
    border-left: 40vw solid transparent;
    border-right: 40vw solid transparent;
    box-sizing: border-box;
    z-index: 30;
    transform-origin: top;
}
.envelope::after {
    content: '';
    position: absolute;
    top: 0;
    left:0;
    width: 80vw;
    height: 25vh;
    z-index: 25;
    background: linear-gradient(30deg, var(--base-dark) 47%, var(--shadow) 50%, var(--base) 50%)
    40vw 12.5vh / 40vw 12.5vh no-repeat,
    linear-gradient(31deg, var(--base) 49%, var(--shadow) 50%, transparent 50%)
    0 0 / 40vw 12.6vh no-repeat,
    linear-gradient(150deg, var(--base) 50%, var(--shadow) 50%, var(--base-dark) 53%)
    0 12.5vh / 40vw 12.7vh no-repeat,
    linear-gradient(148.7deg, transparent 50%, var(--shadow) 50%, var(--base) 51%)
    40vw 0 / 40vw 12.8vh no-repeat;
}

:root {
  --hold: "Pauline", "Ganache", "Ice Creame" "brevia";
  --splashTitleFont: "Nautilus Pompilius", sans-serif;
  --displayFont: "Nautilus Pompilius", sans-serif;
  --titleFont: brandon-grotesque, sans-serif;
  --subTitleFont: brandon-grotesque, sans-serif;
  --contentFont: brandon-grotesque, sans-serif;
  --accentColor: #ffffff;
  --baseColor: #99ffcc;
  --offBlack: black;
  --highlightColor: #99ffcc;
}

.basicPage {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: var(--offBlack);
  color: white;
  z-index: 0;
}

.appStoreBtn {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cacheHelpContainer {
  opacity: 0;
  width: 80%;
  height: 70%;
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: 0.1s;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
}

.cacheHelpText {
  width: 100%;
  height: 20%;
  color: white;
  font-size: 2vh;
}

.cacheHelpBackButton {
  width: 50%;
  height: 20%;
  font-family: var(--titleFont);
  font-size: 4.75vh;
  background-color: var(--offBlack);
  border: 5px solid #151515;
  color: white;
  margin-bottom: 5%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vw;
}

.chattyMsg {
  font-family: var(--contentFont);
  color: white;
  font-style: italic;
  font-size: 2vh;
  margin-top: 2.5vh;
}

.lobbyReadyButton {
  width: 40%;
  height: 60%;
  font-family: var(--titleFont);
  font-size: 2vh;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  color: white;
  margin: 1vh;
  border-radius: 2.5vw;
}

.fade-transition {
  transition: opacity 0.5s ease;
}

.lobbyContent {
  height: 85%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.lobbyIntro {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lobbyInstructions {
  margin: 5%;
  width: 90%;
}

.gridLobbyNameTag {
  height: 20%;
  width: 100%;
  font-size: 1.75vh;
}

.gridLobbyIcon {
  width: auto;
  height: 70%;
}

.lobbyGridUserCard {
  box-shadow: inset 0 0 0 1px #151515; /* Inset border effect */
  position: relative;
  width: auto;
  height: 100%;
}

.lobbyGrid {
  background-color: var(--offBlack);
  width: 100%;
  height: 40vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 25%));
  grid-auto-rows: 33%;
  grid-gap: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.startGameButton {
  width: 70%;
  height: 9%;
  font-family: var(--titleFont);
  font-size: 3.25vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  margin: 1%;
  border-radius: 2.5vw;
  align-self: center;
}

.startGameButton:active {
  background-color: white;
  color: black;
}

.iconSelectButton {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.header-container {
  padding: 0;
}

.lobbyUserInfo {
  display: flex;
  height: 100%;
  width: 100%-3;
  align-items: center;
  border: 5px solid #151515;
}

.lobbyUserIcon {
  float: left;
}

.turnSliderWrapper {
  width: var(--drWidth);
  margin-left: var(--drMargin);
}

/*
  RED FF3333
  ORANGE FF9933
  YELLOW FFFF66
  Green 66cc66
  Teal 99FFCC
  CYAN 33FFFF
  Blue 99CCFF
  Purple D58DFF
  DARK PINK FF6699
  PINK FFCCFF


  var(--color1),
  var(--color2),
  var(--color1),
  var(--color2),
  var(--color1));
*/

/* Styling for the gradient animation on the main box */
.ruleEvent::before {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0; /* Original dimensions of the parent */
  background: repeating-conic-gradient(
    from var(--a),
    var(--color1),
    var(--color2),
    var(--color1),
    var(--color2),
    var(--color1)
  );
  border-radius: 2.5vw; /* Matches the .ruleEvent border-radius */
  animation: rotating 4s linear infinite;
  /* Applying rotation animation */
}

/* Styling for the blurred gradient on the main box */
.ruleEvent::after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0; /* Original dimensions of the parent */
  background: repeating-conic-gradient(
    from var(--a),
    var(--color1),
    var(--color2),
    var(--color1),
    var(--color2),
    var(--color1)
  );
  border-radius: 2.5vw; /* Matches the .ruleEvent border-radius */
  animation: rotating 4s linear infinite;
  /* Applying rotation animation */

  opacity: 0.75;
}

/* Styling for the inner text container */
.ruleEventText {
  width: calc(100% - 4px); /* Smaller to reveal 4px inset on each side */
  height: calc(100% - 4px); /* Smaller to reveal 4px inset on each side */
  transition: color 0.5s linear;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 50;
  border-radius: calc(
    2.5vw - 2px
  ); /* Adjusted to stay consistent with the smaller dimensions */
  background-color: black;
  position: absolute;
  display: flex;

  inset: 2px; /* Creates the 4px gap */
}

/* Custom property for the rotation angle */
@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

/* Keyframes for the rotating animation */
@keyframes rotating {
  0% {
    --a: 0deg;
  }

  100% {
    --a: 360deg;
  }
}

.ruleEvent {
  left: 3%;
  position: absolute;
  height: 65.5%;
  width: 47%;
  max-width: 400px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 2.5vw;
  align-self: center;
  font-size: 1.65vh;
  opacity: 0;
  animation: fadeIn 1s linear 0.5s forwards;
  margin-top: 1%;
}

.joinSign {
  width: 30%;
  height: 100%;
  right: 0;
  position: absolute;
  border-radius: 2.5vw;
}

/* Font size for smaller viewports (less than 600px height) */
@media (max-height: 600px) {
  .joinCodeText {
    font-size: 10px;
  }
}

/* Font size for medium-sized viewports (between 600px and 800px height) */
@media (min-height: 601px) and (max-height: 800px) {
  .joinCodeText {
    font-size: 16px;
  }
}

/* Font size for larger viewports (greater than 800px height) */
@media (min-height: 801px) {
  .joinCodeText {
    font-size: 22px;
  }
}
.joinCodeText {
  position: absolute; /* Position text over the image */
  top: 55%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
  padding: 20px; /* Add spacing around text */
  border-radius: 5px; /* Optional rounded corners */
  text-align: center; /* Center text alignment */
  width: 100%;
  color: var(--baseColor);
  text-shadow: 0px 0px 3px var(--baseColor);
}

.joinCodeOverlay {
  width: auto; /* Full width of `.joinSign` */
  height: 100%; /* Full height of `.joinSign` */
  position: relative; /* Allows absolute positioning inside it */
  object-fit: cover; /* Ensures image scales correctly */
}
.gameHeader {
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  height: 7.5%;
  display: flex;
  background-color: transparent; /* or absolute, depending on your layout */
}

.turnSliderLabel {
  width: 100%;
  height: 5%;
  font-size: 2.5vh;
  font-family: var(--contentFont);
  color: #151515;
  text-align: left;
  justify-self: right;
  z-index: 3;
  margin-left: -5vw;
}

.turnSliderContainer {
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 3;
  transition: opacity 0.75s ease-in-out;
}
.turnSlider {
  height: 100%;
  width: 20vw;
  min-width: 20vw;
  border-top: 5px solid #151515;

  align-items: center;
  justify-content: center;
  z-index: 3;
}

.turnSliderIcon {
  width: 100%;
  z-index: 3;
  transition: filter 0.5s linear;
}

.turnSliderIcon.first {
  filter: grayscale(0%);
}

.turnSliderIcon.dull {
  filter: grayscale(100%) !important;
  opacity: 0.6;
}

.lobbyGameTitle {
  font-family: var(--titleFont);
  color: var(--baseColor);
  display: block;
  width: 100%;
  font-size: 3vh;
  margin-right: 30%;
}

.blinkSlowElement {
  animation: blinkOn 2s 0s 1;
}

.blinkFastElement {
  animation: blinkOn 1s 0s 1;
}

.pulsePlayerColor {
  animation: pulsePlayerColorAnimation 4s infinite linear;
}

.pulseElement {
  animation: pulseAnimation 4s infinite ease-in-out;
}

.subTitlePulseElement {
  animation: subTitlePulseAnimation 4s infinite linear;
}

.splashPreRenderContainer {
  top: 5.5vh;
  left: 0;
  width: 100vw;
  height: 94vh;
  z-index: -500;
  transform: translateZ(-100px);
}

.cardSplashArt {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 0;
  animation: fadeOut 0.2s linear 1s 1 forwards;
}

.splashArt {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 0;
  animation: fadeOut 0.2s linear 2.7s 1 forwards;
}

@keyframes pulseAnimation {
  0% {
    text-shadow: 0 0 3px var(--baseColor);
  }
  50% {
    text-shadow: 0 0 9px var(--baseColor);
  }
  100% {
    text-shadow: 0 0 3px var(--baseColor);
  }
}

@keyframes pulsePlayerColorAnimation {
  0% {
    text-shadow: 0 0 3px var(--playerColor);
  }
  50% {
    text-shadow: 0 0 7px var(--playerColor);
  }
  100% {
    text-shadow: 0 0 3px var(--playerColor);
  }
}

@keyframes subTitlePulseAnimation {
  0% {
    text-shadow: 0 0 8px var(--baseColor);
  }
  50% {
    text-shadow: 0 0 1px var(--baseColor), 0 0 10px var(--baseColor);
  }
}

@keyframes pulseDiceAnimation {
  0% {
    text-shadow: 0 0 12px var(--baseColor);
  }
  50% {
    text-shadow: 0 0 5px var(--baseColor), 0 0 14px var(--baseColor);
  }
}

@keyframes slideLeft {
  80% {
    transform: translateX(-23vw);
  }
  100% {
    transform: translateX(-20vw);
  }
}

.reconnectBtn {
  width: 70%;
  height: 10%;
  font-family: var(--subTitleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  color: white;
  margin: 1vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  text-align: center;
}

.lvlPickLable {
  font-size: 2.75vh;
  font-family: var(--titleFont);

  margin-bottom: 1.5vh;
  color: #99ffcc;
}

.lvlLable {
  font-size: 2vh;
  font-family: var(--contentFont);
  font-style: italic;
  white-space: pre-line;
  margin-bottom: 2%;
}

.lvlPickBtnContainer {
  width: 100%;
}
.lvlPickBtn {
  background-color: var(--offBlack);
  width: 33%;
  font-size: 7vh;
  border: 0px solid;
  transform: translateX(-5%);
}

.lvlContainer {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lvlPickContainer {
  width: 100%;
  height: 30%;
  margin-bottom: 5vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.collapsedLvlPickContainer {
  width: 100%;
  border-bottom: 5px solid #151515;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 15%;
}

.titleWrapper {
  position: relative; /* Create a positioning context for child elements */
  overflow: hidden; /* To ensure the wave doesn't spill out of the wrapper */
}
.titleWave {
  position: relative;
}

.formGameTitle {
  font-family: var(--splashTitleFont);
  text-transform: none !important;
  text-shadow: 0 0 3px var(--baseColor);
  color: var(--baseColor);
  font-weight: 400 !important;
  animation: bobbing 10s ease-in-out infinite;
  display: block;
  width: auto;
  font-size: 6.5vh;
  text-align: center;
  white-space: pre-line;
}

@keyframes bobbing {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(2deg);
  }
}

.bannerText {
  font-family: var(--titleFont);
  font-size: 3vh;
  text-shadow: 0 0 10px var(--playerColor, #99ffcc);
  color: var(--playerColor, #99ffcc);
  transition: color 1s linear, text-shadow 1s linear;
}

.ruleDisplay {
  font-family: var(--subTitleFont);
  font-size: 2vh;
  color: white;
  position: absolute;
  right: 5px;
  max-width: 40vw;
  height: 5%;
  line-height: 100%;
  justify-content: center;
}

.altTitle {
  color: white;
}

.lobbyUserName {
  font-family: var(--displayFont);
  font-size: 3.25vh !important;
  text-shadow: none !important;
}

.promptSubtitle {
  font-family: (var(--contentFont));
  font-size: 2.5vh;
  margin-top: 10%;
  font-style: italic;
  margin-bottom: 5vh;
  color: black;
}

.lobbyRoomCode {
  font-family: var(--titleFont);
  font-size: 4.5vh;
  text-shadow: 0 0 8px var(--baseColor);
  color: var(--baseColor);
  display: inline-block;
}

.lobbyRoomCodePrompt {
  font-family: var(--titleFont);
  font-size: 4.5vh;
  color: var(--accentColor);
  display: inline-block;
}

.gamePiecePrompt {
  font-family: var(--displayFont);
  text-transform: none !important;
  font-size: 4vh;
  color: var(--baseColor);
  border: none;
}
.labelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
  height: 100%;
}

.lobbyLogo {
  margin: 1.25vh;
  animation: bob1 7s linear infinite;
}

.labelText {
  font-size: 2.5vh;
  margin-bottom: 1%;
  color: gray;
  width: 100%;
  text-align: left;
  font-family: var(--contentFont);
  display: flex;
}
.charactersRemaining {
  font-size: 2.5vh;
  color: gray;
  right: 0;
  width: 100%;
  text-align: right;
  font-family: var(--contentFont);
}

.formOtpInput {
  width: 25% !important;
  height: 100%;
  font-family: var(--subTitleFont);
  font-size: 4vh;
  padding: 0;
  border-radius: 2.5vw;
}

.formOtpContainer {
  width: 100%;
  height: 50%;
  justify-content: center;
  overflow-wrap: hidden;
  grid-column-gap: 4%;
  column-gap: 4%;
  padding: 0;
}
.appStoreAccentPrompt {
  justify-content: center;
  display: inline-block;
  font-size: 2.5vh;
  white-space: pre-line;
  color: var(--baseColor);
  text-shadow: 0 0 3px var(--baseColor);
  font-family: var(--subTitleFont);
  margin-bottom: 3vh;
}
.appStorePrompt {
  justify-content: center;
  display: inline-block;
  font-size: 2.5vh;
  white-space: pre-line;
  color: var(--accentColor);
  text-shadow: 0 0 3px var(--accentColor);
  font-family: var(--subTitleFont);
  margin-top: 3vh;
}

.storeButton {
  width: 200px;
  height: auto;
  margin: 10px;
}

.appStoreContainer {
  opacity: 0;
  width: 90%;
  height: 70%;
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: 0.1s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formInput {
  width: 100%;
  height: 50%;
  font-family: var(--contentFont);
  font-size: 3vh;
  border-radius: 2.5vw;
  padding: 3%;
}

.appStoreBackButton {
  width: 50%;
  height: 12.5%;
  font-family: var(--titleFont);
  font-size: 4.75vh;
  background-color: var(--offBlack);
  border: 5px solid #151515;
  color: white;
  margin: 5%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vw;
  margin-top: 5vh;
}

.orHostLabel {
  color: gray;
  width: 100%;
  flex: 1 1;
  font-family: var(--contentFont);
  font-size: 2.5vh;
  display: block;
}

.hostLink {
  color: white;
  font-family: var(--displayFont);
  margin: 5%;
  text-decoration: underline;
  text-transform: none !important;
  font-size: 3vh;
  width: 100%;
  flex: 1 1;
}

.formButton {
  width: 45%;
  height: 35%;
  font-family: var(--displayFont);
  font-size: 3.75vmax;
  background-color: var(--offBlack);
  border: 5px solid #151515;
  color: gray;
  padding: 2%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vw;
  text-transform: none !important;
  margin-top: 5%;
}

.formButton.open {
  color: white;
  border-color: var(--baseColor);
}

.formButton.hostButton {
  color: #151515;
  border-color: #151515;
}

.formButton:active {
  background-color: white;
  color: black;
}

.turnPlayerIcon {
  width: var(--drWidth);
  height: var(--drWidth);
  animation: drink-shaking 2s infinite linear;
  transition: opacity 1.5s ease-in-out;
  z-index: 3;
}

.lvlButton {
  width: 40%;
  height: 10%;
  font-family: var(--titleFont);
  font-size: 3.5vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  margin-top: 5vh;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 2.5vw;
}

.happyHourIdlePlayer {
  height: auto;
  width: 35%;
  z-index: 1;
}

.happyHourSign {
  height: 10%;
  width: 100%;
  font-family: var(--displayFont);
  text-transform: none !important;
  font-size: 5vh;
  text-align: center;
  transition: opacity 1.5s ease-out;
  display: flex;
  padding-top: 10vh;
  margin-bottom: 5vh;
  justify-content: center;
}

.startMiniGame {
  width: 80%;
  height: 20%;
  font-family: var(--displayFont), sans-serif;
  text-transform: none !important;
  font-size: 6.5vh;
  animation: fadeIn 0.5s linear forwards; /* focus-in 1s linear 0s 1 forwards, animate-pulse-focus 2s linear 1.1s infinite; TODO */
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  border-radius: 2.5vw;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 37%;
  left: 10%;
  z-index: 500;
}

.pulse-focus {
  animation: focus-in 0.2s linear 0s 1 forwards,
    animate-pulse-focus 2s linear 0.2s infinite !important;
}

.rollBtn {
  width: 70%;
  height: 15%;
  font-family: var(--displayFont), sans-serif;
  text-transform: none !important;
  font-size: 7vh;
  animation: animate-pulse 2s linear infinite;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  border-radius: 2.5vw;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 42.5%;
  left: 15%;
}

.rollBtn:active {
  background-color: white;
  color: black;
}

.alertBtn {
  width: 90%;
  height: 55%;
  font-family: var(--subTitleFont), sans-serif;
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  border-radius: 2.5vw;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30%;
  left: 5%;
  z-index: 5;
  white-space: pre-line;
  box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.8);
}

.stopBtn {
  width: 45%;
  height: 75%;
  font-family: var(--titleFont);
  font-size: 4vh;
  color: white;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  animation: flashText 0.75s infinite;
}

@keyframes flashBackground {
  0% {
    background-color: var(--baseColor);
  }
  50% {
    background-color: var(--offBlack);
  }
  100% {
    background-color: var(--baseColor);
  }
}

@keyframes animate-subtle-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    text-shadow: 0 0 2px var(--baseColor);
  }
  40% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    text-shadow: 0 0 4px rgba(255, 255, 255, 0);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    text-shadow: 0 0 4px var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    text-shadow: 0 0 2px var(--baseColor);
  }
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0.7);
    text-shadow: 0 0 10px var(--accentColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px var(--accentColor);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    text-shadow: 0 0 10px var(--accentColor);
  }
}

@keyframes focus-in {
  0% {
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.65);
  }
}

@keyframes focus-out {
  0% {
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.65);
  }
  100% {
    box-shadow: none;
  }
}

@keyframes animate-pulse-focus {
  0% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0.7),
      0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 10px var(--accentColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0),
      0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 3px var(--accentColor);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0),
      0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 3px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 10px var(--accentColor);
  }
}

@keyframes animate-pulse-teal {
  0% {
    box-shadow: 0 0 0 0 var(--baseColor);
    text-shadow: 0 0 10px var(--baseColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px rgba(153, 255, 204, 0);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0);
    text-shadow: 0 0 10px var(--baseColor);
  }
}

@keyframes animate-pulse-highlight1 {
  0% {
    box-shadow: 0 0 0 0 var(var(--baseColor));
    text-shadow: 0 0 10px var(--baseColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px rgba(153, 255, 204, 0);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0);
    text-shadow: 0 0 10px var(--baseColor);
  }
}

@keyframes animate-pulse-highlight-roulette {
  50% {
    color: #151515;
    box-shadow: 0 15px 15px black;
  }
}

@keyframes animate-pulse-highlight {
  0% {
    color: var(--accentColor);
    text-shadow: 0 0 0px var(--accentColor);
    box-shadow: 0 0 0 0 var(--accentColor);
    border-color: var(--accentColor);
  }
  40% {
    color: var(--accentColor);
    text-shadow: 0 0 5px var(--accentColor);
    box-shadow: 0 0 0 0 var(--accentColor);
    border-color: var(--accentColor);
  }
  80% {
    color: #151515;
    text-shadow: 0 0 5px rgba(153, 255, 204, 0);
    box-shadow: 0 0 0 10px rgba(153, 255, 204, 0);
    border-color: #151515;
  }
  100% {
    color: var(--accentColor);
    text-shadow: 0 0 0px var(--accentColor);
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0);
    border-color: var(--accentColor);
  }
}

@keyframes animate-pulse-white {
  0% {
    box-shadow: 0 0 0 0 white;
  }
  40% {
    box-shadow: 0 0 0 30px rgba(255, 109, 74, 0);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 109, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0);
  }
}
@keyframes animate-pulse-dice {
  0% {
    box-shadow: 0 0 0 0 white;
    text-shadow: 0 0 10px white;
    color: white;
    border-color: white;
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px var(--baseColor);
    color: var(--baseColor);
    border-color: var(--baseColor);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px var(--baseColor);
    color: var(--baseColor);
    border-color: var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0);
    border-color: var(--baseColor);
    color: var(--baseColor);
  }
}

@keyframes animate-pulse-cursed-dice {
  0% {
    box-shadow: 0 0 0 0 magenta;
    text-shadow: 0 0 10px white;
    border-color: white;
    color: white;
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px magenta;
    border-color: magenta;
    color: magenta;
  }
  80% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px magenta;
    border-color: magenta;
    color: magenta;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0);
    border-color: magenta;
    color: magenta;
  }
}

@keyframes flashText {
  0% {
    color: white;
  }
  50% {
    color: var(--accentColor);
  }
  100% {
    color: white;
  }
}

.formGameTitle .fast {
  animation: blink 0.3s 1s 8;
}
.formGameTitle .flickerOn {
  animation: blinkOn 2s 0s 1;
}

.formGameTitle .delay {
  animation: blink 2.5s 1.5s 8;
}

.formGameTitle .delayeder {
  animation: blink 6s 3.25s infinite;
}

@keyframes blink {
  78% {
    color: inherit;
  }
  79% {
    color: var(--offBlack);
  }
  80% {
  }
  81% {
    color: inherit;
  }
  82% {
    color: var(--offBlack);
  }
  83% {
    color: inherit;
  }
  92% {
    color: var(--offBlack);
  }
  92.5% {
    color: inherit;
  }
}

@keyframes blinkOn {
  0% {
    opacity: 0%;
  }
  20% {
    opacity: 0%;
  }
  21% {
    opacity: 50%;
  }
  22% {
    opacity: 10%;
  }
  24% {
    opacity: 75%;
  }
  30% {
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  90% {
    opacity: 100%;
  }
  91% {
    opacity: 0;
  }
  93% {
    opacity: 100%;
  }
  94% {
    opacity: 0%;
  }
  95% {
    opacity: 100%;
  }
  97% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.lvlSlider {
  -webkit-appearance: none;
  width: 60%;
  height: 50%;
  background: #151515;
  border-radius: 5%/95%;
  z-index: 1;
}

.lvlSlider::before {
  content: "";
  width: calc(30% * (var(--val))); /* Calculate the width based on the value */
  height: 1%;
  background: var(--baseColor); /* Fill color */
  position: absolute;
  border-radius: 5%/95%;
  z-index: 0;
}

.lvlSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4.5vh;
  height: 4.5vh;
  background: var(--baseColor); /* Thumb color */
  border-radius: 50%;
}

.sliderScale {
  width: 100%;
  justify-content: space-between;
  font-size: 4.5vh;
  height: 10%;
}

.slider-values {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 5px; /* Adjust this margin as needed */
  width: 90%;
  height: 10%;
}

@keyframes circle {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-175vh);
  }
}

@keyframes floatOff {
  0% {
    opacity: 100;
    transform: translateY(0) rotate(0deg);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-150vh) rotate(70deg);
  }
}

.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1 !important;
  transform: translateZ(-1px);
}

.bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  border-color: var(--playerColor, white);
  bottom: -100px;
  animation: circle 5s infinite;
  transition-timing-function: linear;
  border-radius: 50%;
  border: solid 2px var(--playerColor, white);
  z-index: -5;
}

.bubbles li:nth-child(1) {
  width: 8px;
  height: 8px;
  left: 25%;
  animation-delay: 1.5s;
  animation-duration: 11s;
}

.bubbles li:nth-child(2) {
  width: 14px;
  height: 14px;
  left: 40%;
  animation-delay: 0.5s;
  animation-duration: 11s;
}

.bubbles li:nth-child(3) {
  width: 11px;
  height: 11px;
  left: 60%;
  animation-delay: 2s;
  animation-duration: 10s;
}

.bubbles li:nth-child(4) {
  width: 16px;
  height: 16px;
  left: 80%;
  animation-delay: 0s;
  animation-duration: 15s;
}

.bubbles li:nth-child(5) {
  width: 11px;
  height: 11px;
  left: 50%;
  animation-delay: 3s;
  animation-duration: 9s;
}

.bubbles li:nth-child(6) {
  width: 9px;
  height: 9px;
  left: 10%;
  animation-delay: 2s;
  animation-duration: 8s;
}

.bubbles li:nth-child(7) {
  width: 13px;
  height: 13px;
  left: 15%;
  animation-delay: 1s;
  animation-duration: 9s;
}

.bubbles li:nth-child(8) {
  width: 8px;
  height: 8px;
  left: 30%;
  animation-delay: 2.5s;
  animation-duration: 9s;
}

.bubbles li:nth-child(9) {
  width: 13px;
  height: 13px;
  left: 30%;
  animation-delay: 1.5s;
  animation-duration: 9s;
}

.bubbles li:nth-child(10) {
  width: 5px;
  height: 5px;
  left: 70%;
  animation-delay: 0.5s;
  animation-duration: 5s;
}

.bubbles li:nth-child(11) {
  width: 11px;
  height: 11px;
  left: 15%;
  animation-delay: 0.75s;
  animation-duration: 7s;
}

.bubbles li:nth-child(12) {
  width: 8px;
  height: 8px;
  left: 80%;
  animation-delay: 0.25s;
  animation-duration: 6s;
}

.bubbles-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  transform: translateZ(1px);
  opacity: 0;
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: 1.1s;
}

.bubbles-card li {
  position: absolute;
  list-style: none;
  display: block;
  border-color: var(--bubble-color, rgba(0, 0, 0, 0.3));
  bottom: -100px;
  animation: circle 5s infinite;
  transition-timing-function: linear;
  border-radius: 50%;
  border: solid 2px var(--bubble-color, rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.bubbles-card li:nth-child(1) {
  width: 18px;
  height: 18px;
  left: 25%;
  animation-delay: 1.5s;
  animation-duration: 16s;
}

.bubbles-card li:nth-child(2) {
  width: 24px;
  height: 24px;
  left: 40%;
  animation-delay: 0.5s;
  animation-duration: 16s;
}

.bubbles-card li:nth-child(3) {
  width: 21px;
  height: 21px;
  left: 60%;
  animation-delay: 2s;
  animation-duration: 15s;
}

.bubbles-card li:nth-child(4) {
  width: 26px;
  height: 26px;
  left: 80%;
  animation-delay: 0s;
  animation-duration: 20s;
}

.bubbles-card li:nth-child(5) {
  width: 21px;
  height: 21px;
  left: 50%;
  animation-delay: 3s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(6) {
  width: 19px;
  height: 19px;
  left: 10%;
  animation-delay: 2s;
  animation-duration: 13s;
}

.bubbles-card li:nth-child(7) {
  width: 23px;
  height: 23px;
  left: 15%;
  animation-delay: 1s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(8) {
  width: 18px;
  height: 18px;
  left: 30%;
  animation-delay: 2.5s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(9) {
  width: 23px;
  height: 23px;
  left: 30%;
  animation-delay: 1.5s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(10) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-delay: 0.5s;
  animation-duration: 10s;
}

.bubbles-card li:nth-child(11) {
  width: 21px;
  height: 21px;
  left: 15%;
  animation-delay: 0.75s;
  animation-duration: 12s;
}

.bubbles-card li:nth-child(12) {
  width: 18px;
  height: 18px;
  left: 80%;
  animation-delay: 0.25s;
  animation-duration: 11s;
}

.bubbles-card li:nth-child(13) {
  width: 18px;
  height: 18px;
  left: 30%;
  animation-delay: 6s;
  animation-duration: 10s;
}

.bubbles-card li:nth-child(14) {
  width: 23px;
  height: 23px;
  left: 50%;
  animation-delay: 5s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(15) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-delay: 5.5s;
  animation-duration: 10s;
}

.bubbles-card li:nth-child(16) {
  width: 21px;
  height: 21px;
  left: 15%;
  animation-delay: 8s;
  animation-duration: 12s;
}

.bubbles-card li:nth-child(17) {
  width: 18px;
  height: 18px;
  left: 80%;
  animation-delay: 7s;
  animation-duration: 11s;
}

.bubbles-hh {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  transform: translateZ(1px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  animation: fadeIn 0.5s linear 1 forwards;
}

.bubbles-hh.hidden {
  opacity: 0 !important;
}

.bubbles-hh li {
  position: absolute;
  list-style: none;
  display: block;
  border-color: var(--bubble-color, rgba(0, 0, 0, 0.3));
  bottom: -100px;
  animation: circle 5s infinite;
  transition-timing-function: linear;
  border-radius: 50%;
  border: solid 2px var(--bubble-color, rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.bubbles-hh li:nth-child(1) {
  width: 21px;
  height: 21px;
  left: 25%;
  animation-delay: 1.5s;
  animation-duration: 16s;
}

.bubbles-hh li:nth-child(2) {
  width: 27px;
  height: 27px;
  left: 40%;
  animation-delay: 0.5s;
  animation-duration: 16s;
}

.bubbles-hh li:nth-child(3) {
  width: 24px;
  height: 24px;
  left: 60%;
  animation-delay: 2s;
  animation-duration: 15s;
}

.bubbles-hh li:nth-child(4) {
  width: 29px;
  height: 29px;
  left: 80%;
  animation-delay: 0s;
  animation-duration: 20s;
}

.bubbles-hh li:nth-child(5) {
  width: 24px;
  height: 24px;
  left: 50%;
  animation-delay: 3s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(6) {
  width: 22px;
  height: 22px;
  left: 10%;
  animation-delay: 2s;
  animation-duration: 13s;
}

.bubbles-hh li:nth-child(7) {
  width: 26px;
  height: 26px;
  left: 15%;
  animation-delay: 1s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(8) {
  width: 21px;
  height: 21px;
  left: 30%;
  animation-delay: 2.5s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(9) {
  width: 26px;
  height: 26px;
  left: 30%;
  animation-delay: 1.5s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(10) {
  width: 18px;
  height: 18px;
  left: 70%;
  animation-delay: 0.5s;
  animation-duration: 10s;
}

.bubbles-hh li:nth-child(11) {
  width: 24px;
  height: 24px;
  left: 15%;
  animation-delay: 0.75s;
  animation-duration: 12s;
}

.bubbles-hh li:nth-child(12) {
  width: 21px;
  height: 21px;
  left: 80%;
  animation-delay: 0.25s;
  animation-duration: 11s;
}

.bubbles-hh li:nth-child(13) {
  width: 15px;
  height: 15px;
  left: 50%;
  animation-delay: 8s;
  animation-duration: 10s;
}

.bubbles-hh li:nth-child(14) {
  width: 24px;
  height: 24px;
  left: 65%;
  animation-delay: 7s;
  animation-duration: 12s;
}

.bubbles-hh li:nth-child(15) {
  width: 21px;
  height: 21px;
  left: 80%;
  animation-delay: 7.5s;
  animation-duration: 11s;
}

.bubbles-hh li:nth-child(16) {
  width: 18px;
  height: 18px;
  left: 10%;
  animation-delay: 8.5s;
  animation-duration: 10s;
}

.bubbles-hh li:nth-child(17) {
  width: 24px;
  height: 24px;
  left: 15%;
  animation-delay: 7.75s;
  animation-duration: 12s;
}

.bubbles-hh li:nth-child(18) {
  width: 21px;
  height: 21px;
  left: 40%;
  animation-delay: 8.25s;
  animation-duration: 11s;
}

.bubbles-hh li:nth-child(19) {
  width: 18px;
  height: 18px;
  left: 35%;
  animation-delay: 9s;
  animation-duration: 6s;
}

.bubbles-hh li:nth-child(20) {
  width: 21px;
  height: 21px;
  left: 60%;
  animation-delay: 9.75s;
  animation-duration: 11s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

