html, body{
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
  overflow: hidden;
  background: black;
}

*{
  font-weight: 550 !important;
  text-transform: uppercase;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


