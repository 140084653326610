@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Bebas+Neue");
@import url("https://fonts.googleapis.com/css?family=Monoton");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Germania+One");
@import url("https://fonts.googleapis.com/css?family=Tilt+Neon");
@import url("https://fonts.googleapis.com/css?family=Neonderthaw");
@import url("https://fonts.googleapis.com/css?family=Train+One");
@import url("https://fonts.googleapis.com/css?family=Abel");

.splashContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: None;
  color: white;
  transition: opacity 0.5s ease-in-out;
  will-change: top, left, width, height;
  position: relative;
  z-index: 0;
}

.resultPage {
  z-index: 5;
  height: 93%;
  width: 100vw;
  display: flex;
  background-color: black;
  position: absolute;
  top: 7%;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drEvent {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.zoomTile {
  animation: zoomToFill 0.75s forwards;
  z-index: 5;
  will-change: top, left, width, height;
}

@keyframes zoomToFill {
  100% {
    top: 0%;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 5;
  }
}

@keyframes fadeinBackground {
  100% {
    background-color: var(--offBlack);
  }
}

.miniGameCard {
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 5;
}

.resultsGraph {
  width: 100%;
  height: 80%;
  justify-content: center;
  padding: auto;
  overflow-y: auto;
}

.resultsList {
  width: 100%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 100%));
  grid-auto-rows: 10%;
  justify-content: center;
  row-gap: 5%;
  margin-top: 5%;
  padding: auto;
  overflow-y: auto;
  z-index: 5;
  background-color: black;
}

.progress-bar {
  width: 100%;
  height: 15%;
  background-color: var(--offBlack);
}

.progress-bar__fill {
  height: 100%;
  background-color: black;
  transition: width 1s ease-out;
  z-index: 0;
  border-radius: 10px;
}

.progress-bar__text {
  margin-top: 8px;
  margin-left: 5%;
  text-align: left;
  opacity: 0;
  font-family: (var(--contentFont));
  font-size: 2vh;
}

.inputResultText {
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  opacity: 0;
  font-family: (var(--contentFont));
  display: flex;
  font-size: 2.75vh;
  border-bottom: #151515 solid 5px;
  position: relative;
  z-index: 2;
  align-items: center;
  white-space: nowrap;
}

.waitingGifContainer {
  display: flex;
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;
}

.flipped {
  transform: rotateY(180deg);
}

.titleContainer {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 6vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  align-items: center;
  z-index: 2;
  padding-top: 2.5%;
}

.eventIcon {
  width: 25vh;
  margin: -5%;
  height: 25vh;
  opacity: 0;
  animation: zoomText 0.2s linear 0.4s 1 forwards;
}

.rulesContainer {
  position: relative;
}

.waveClass {
  position: absolute;
  bottom: 0;
  height: 0%;
  background: none;
  z-index: 1;
  display: flex;
  animation: fillUpScreen 1.25s ease-out 0s 1 forwards;
  will-change: height;
}

.quickWaveClass {
  position: absolute;
  bottom: 0;
  height: 0%;
  background: none;
  z-index: 1;
  display: flex;
  animation: fillUpScreen 1.25s ease-out 0s 1 forwards;
  will-change: height;
}

.movieEmojiWrapper {
  height: 20%;
  width: 100%;
  position: relative;
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  column-gap: 5%;
  font-size: 8vh;
}

.movieChalPrompt {
  height: 25%;
  width: 90%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  color: black;
  align-content: center;
  white-space: pre-line;
  opacity: 0;
  animation: zoomText 0.2s linear 0.2s 1 forwards;
  z-index: 2;
  margin: auto;
  font-size: 3.05vh;
}

.movieChalButtons {
  width: 90%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5%;
}

.moviesChalButton {
  width: 45%;
  height: 55%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  border-radius: 2.5vw;
  color: var(--accentColor);
}

.rulesTitleContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.2s infinite linear;
  padding-top: 15%;
  white-space: pre-line;
}
.mineLogo {
  width: auto;
  height: 60%;
  align-self: center;
  justify-self: center;
}
.mineFlipCard {
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 5vh;
  align-items: center;
  justify-content: center;
  display: flex;
  perspective: 1000px;
  border-radius: 6px;
}

.mineFlipCard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.mineFlipCard.flipped .mineFlipCard-inner {
  transform: rotateY(180deg);
}

.mineFlipCard-front,
.mineFlipCard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: opacity 0.15s ease-in-out;
}

.mineFlipCard-back {
  transform: rotateY(180deg);
}

.minesCounterTitle {
  justify-self: left;
  height: 30%;
  align-content: center;
  margin-right: 0.5em;
  text-transform: none !important;
}

.minesCounter {
  border: 5px solid #151515;
  border-radius: 5%;
  align-content: center;
  width: 2em;
  height: 2em;
  font-size: 4.5vh;
  margin-left: 0.5em;
}

.minesCounterContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 2.5vh;
  white-space: pre-line;
}
.heroTitleContainer {
  background-color: none;
  position: relative;
  height: 15%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 6vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 3vh;
  white-space: pre-line;
}

.minesTitleContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 2.5vh;
  white-space: pre-line;
}

.mineSubtitleContainer {
  background-color: none;
  position: relative;
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 2.75vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  white-space: pre-line;
}

.rouletteOdometer {
  background-color: none;
  position: relative;
  height: 15%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  white-space: pre-line;
  transition: opacity 0.3s linear;
}
.odometer {
  overflow: hidden;
  height: 2em;
  width: 2em;
  position: relative;
  border-radius: 1vh;
  border: 5px solid #151515;
}

.odNumber {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  font-weight: 700;
  font-size: 5.25vh;
}

.odNumber.scroll {
  animation: scrollAnimation 0.5s ease-out forwards, glowText 0.75s linear;
}

.odNumber.glow {
}

@keyframes glowText {
  0% {
    color: black;
  }
  20% {
    color: var(--baseColor);
  }
  100% {
    color: black;
  }
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
}

.odNumber-item {
  height: 2em; /* Match this to the .odometer height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.roulettePrompt {
  background-color: none;
  position: relative;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 3.5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding-top: 2.5vh;
  white-space: pre-line;
}

.rouletteContent {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: space-evenly;
}

.rouletteColumn {
  display: flex;
  width: 30%;
  height: 100%;
  flex-direction: column;
  border-top-left-radius: 50px; /* Rounded top left */
  border-top-right-radius: 50px; /* Rounded top right */
  border-bottom-left-radius: 0; /* Straight bottom left */
  border-bottom-right-radius: 0; /* Straight bottom right */
  align-items: center;
}

.rouletteOption {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 5px solid white;
  box-shadow: 0 5px 5px black;
  margin-bottom: 10%;
}

.rouletteColumn.dull {
  filter: grayscale(100%) !important;
}

.rouletteIcon {
  width: 60%;
  height: auto;
}
.loser {
  color: white;
  font-size: 3vh;
  text-shadow: 0 0 10px var(--accentColor);
}

.loserAlert {
  width: 70%;
  height: auto;
  font-family: var(--subTitleFont), sans-serif;
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  border-radius: 2.5vw;
  text-align: center !important;
  padding: 2%;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 30%;
  left: 15%;
  z-index: 500;
  white-space: pre-line;
  display: flex;
  animation: fadeIn 0.5s linear forwards;
}

.wheel {
  position: absolute;
  width: 50vh;
  height: 50vh;
  top: calc(50% - 49vh / 2);
  left: calc(50% - 50vh / 2);
  border-radius: 50%;
  border: 8px solid rgb(255, 255, 255);
  margin: -1vh; /* 8px push border */
  overflow: hidden;
  transition: transform 5s ease;
}

.wheel:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  z-index: 1;
}

.wheel .inner {
  width: 50%;
  height: 50%;
  transition: transform 5s ease;
}

.wheel .inner .slice {
  position: absolute;
  width: 0;
  height: 0;
  left: 17vh;
  top: -1.5vh;
  border-width: 25.5vh 7.25vh 0 7vh;
  border-style: solid;
  transform-origin: 50% 100%;
}

.wheel .inner .slice .prize {
  position: relative;
  display: block;
  transform: rotateZ(90deg);
  left: 1vh;
  text-align: center;
  margin-top: -23vh;
  margin-left: -2vh;
}

.wheelWrapper {
  height: 50vh;
  width: 100%;
}
.wheelTitleContainer {
  background-color: none;
  position: relative;
  height: 25%;
  width: 95%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  animation: float 3.75s infinite linear;
  padding-top: 2.5vh;
  white-space: pre-line;
}

.prizeIcon {
  width: 10vh;
  height: 10vh;
  transform: rotate(-90deg) translateY(-3vh);
}

.wheelArrow {
  width: 4%;
  height: 10%;
  display: flex;
  position: absolute;
  z-index: 100;
  top: 20%;
  left: 48%;
  color: var(--baseColor);
  justify-content: center;
  font-size: 5vh;
}
/* Slices colors and rotations */
.wheel .inner .slice:nth-child(1) {
  transform: rotate(-30deg);
}
.wheel .inner .slice:nth-child(2) {
  transform: rotate(-60deg);
}
.wheel .inner .slice:nth-child(3) {
  transform: rotate(-90deg);
}
.wheel .inner .slice:nth-child(4) {
  transform: rotate(-120deg);
}
.wheel .inner .slice:nth-child(5) {
  transform: rotate(-150deg);
}
.wheel .inner .slice:nth-child(6) {
  transform: rotate(-180deg);
}
.wheel .inner .slice:nth-child(7) {
  transform: rotate(-210deg);
}
.wheel .inner .slice:nth-child(8) {
  transform: rotate(-240deg);
}
.wheel .inner .slice:nth-child(9) {
  transform: rotate(-270deg);
}
.wheel .inner .slice:nth-child(10) {
  transform: rotate(-300deg);
}
.wheel .inner .slice:nth-child(11) {
  transform: rotate(-330deg);
}
.wheel .inner .slice:nth-child(12) {
  transform: rotate(-360deg);
}

.wheel-outer {
  position: absolute;
  width: 50vh;
  height: 50vh;
  top: calc(50% - 50vh / 2);
  left: calc(50% - 50vh / 2);
  border-radius: 50%;
}

#svg-dotted {
  position: absolute;
  width: 540px;
  height: 540px;
  top: calc(50% - 540px / 2);
  left: calc(50% - 540px / 2);
}

#svg-dotted #circle-dotted {
  fill: transparent;
  stroke: rgb(200, 200, 200);
  stroke-width: 4;
  stroke-dasharray: 0.1 23;
  stroke-dashoffset: 19.5;
  stroke-linecap: round;
}

.wheelButton {
  position: absolute;
  width: 10vh;
  height: 10vh;
  top: calc(50% - 5vh);
  left: calc(50% - 6vh);
  z-index: 10;
  transition: transform 5s ease;
}

.waitingTitle {
  height: 15%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  margin-top: 5vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.resultsTitle {
  height: 15%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 3.5vh;
  font-family: var(--subTitleFont);
  border-bottom: #151515 solid 5px;
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.quipInput {
  width: 80%;
  height: 6vh;
  font-family: var(--contentFont);
  font-size: 2.5vh;
  border-radius: 2.5vw;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.quipVoteButton {
  width: 0;
  height: 0;
  font-family: var(--titleFont);
  font-size: 0;
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  color: var(--accentColor);
  text-shadow: 0 0 5px var(--accentColor);
  margin: 2vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  animation: appear 0.1s linear 0s 1 forwards;
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
}

.quipVoteResult {
  position: relative;
  opacity: 1;
  width: 75%;
  height: 15%;
  font-size: 2.5vh;
  font-family: var(--titleFont);
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  color: var(--accentColor);
  text-shadow: 0 0 5px var(--accentColor);
  margin: 2vh;
  border-radius: 2.5vw;
  z-index: 1;
  align-self: center;
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
}

.quipResultText {
  position: absolute;
  top: 50%; /* Center the text vertically */
  left: 50%; /* Center the text horizontally */
  transform: translate(-50%, -50%); /* Center the text precisely */
  opacity: 0.5;
}

.quipVoteOverlay {
  position: relative; /* Position the overlay relative to the quipVoteResult button */
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  background-color: none;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.quipVoteButton.selected {
  background-color: white;
  border-color: black;
  color: black;
}

.quipNameTag {
  color: black;
  font-size: 2.75vh;
  font-family: var(--titleFont);
  margin-bottom: 0;
}

.quipNameTag.loser {
  color: red;
}

.quipVoteResult.loser {
  border: 6px solid red;
}

.quipTitle {
  height: 25%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4.5vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  padding-top: 5%;
}

.quipWaiting.center {
  align-self: center;
}
.quipPrompt {
  height: 20%;
  width: 80%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  font-style: italic;
  color: black;
  font-size: 2.75vh;
  font-family: var(--titleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.quipSubtitle {
  height: 15%;
  width: 80%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 2.5vh;
  font-family: var(--titleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

@keyframes turnOn {
  0% {
    color: #151515;
  }
  90% {
    color: white;
  }
  91% {
    color: #151515;
  }
  93% {
    color: white;
  }
  100% {
    color: white;
  }
}

@keyframes flickerOn {
  0% {
    color: #151515;
  }
  15% {
    color: white;
  }
  16% {
    color: #151515;
  }
  17% {
    color: white;
  }
  60% {
    color: white;
  }
  61% {
    color: #151515;
  }
  65% {
    color: white;
  }
  67% {
    color: #151515;
  }
  70% {
    color: white;
  }
  100% {
    color: white;
  }
}

@keyframes flicker {
  0% {
    color: white;
  }
  70% {
    color: white;
  }
  72% {
    color: #151515;
  }
  77% {
    color: white;
  }
  78% {
    color: #151515;
  }
  79% {
    color: white;
  }
  100% {
    color: white;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.votePrompt {
  height: 20%;
  width: 90%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.subTitleContainer {
  height: 10%;
  width: 100%;
  display: flex;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: flex-start;
  justify-content: center;
  align-items: flex-start;
}

.leaderboardNavBtn {
  width: 60%;
  height: 6%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  position: absolute;
  bottom: 10%;
}

.leaderboardNavBtn:active {
  background-color: white;
  color: black;
}

.pokerBtnContainer {
  height: 20vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.pokerVoteBtn {
  width: 70%;
  height: 40%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  color: var(--accentColor);
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pokerFlipCard {
  height: 30vh;
  width: 20vh;
  display: flex;
  perspective: 1000px;
  border-radius: 6px;
  opacity: 0;
}

.pokerFlipCard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.pokerFlipCard.flipped .pokerFlipCard-inner {
  transform: rotateY(180deg);
}

.pokerFlipCard-front,
.pokerFlipCard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.pokerFlipCard-back {
  transform: rotateY(180deg);
}

.pokerContainer {
  height: 50vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.pokerCardImage {
  height: auto;
  width: 100%;
}

.pokerPrompt {
  height: 20%;
  width: 90%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 4vh;
  font-family: var(--titleFont);
  align-items: center;
  padding-top: 10vh;
  opacity: 0;
  animation: zoomText 0.2s linear 0.5s 1 forwards;
}

.reactionBtn {
  width: 80%;
  height: 25%;
  font-family: var(--titleFont);
  font-size: 4vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  margin: 1vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
}

.waveSplashArt {
  animation: fadeOut 0.25s linear 1.3s 1 forwards;
}

.splashNavBtn {
  transition: all 0.5s ease-in-out;
  width: 60%;
  height: 12.5%;
  font-family: var(--titleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  text-shadow: 0 0 10px var(--baseColor);
  margin: 3vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  animation: animate-pulse 3s linear infinite;
}

.splashNavBtn.roulette {
  position: absolute;
  top: 65vh;
}

.quipWaitingWrapper {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quipSendBtn {
  width: 30vw;
  height: 7vh;
  font-family: var(--titleFont);
  font-size: 2vh;
  background-color: var(--offBlack);
  border: 5px solid var(--accentColor);
  color: var(--accentColor);
  text-shadow: 0 0 10px var(--accentColor);
  margin-top: 1vh;
  border-radius: 40%/90%;
  z-index: 2;
  left: 25vw;
  animation: animate-pulse-highlight 3s linear infinite;
  position: relative;
}
.splashNavBtn:active {
  background-color: white;
  color: black;
}
.lifeRaftButton {
  width: 80%;
  height: 15%;
  font-family: var(--subTitleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  color: white;
  margin-top: 2vh;
  margin-bottom: 2vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  text-align: center;
}

.shakeElement {
  animation: tilt-shaking 0.2s 1 linear;
}

.choiceChalPrompt {
  height: 30%;
  width: 100%;
  display: flex;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding: 5%;
}

.draggingContainer {
  list-style: none;
  padding-left: 0;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.sortingChalPrompt {
  height: 15%;
  width: 100%;
  display: flex;
  color: black;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
  justify-self: center;
  align-self: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding: 2%;
}

.heroString {
  height: 100%;
  width: 4px;
  background: white;
  z-index: 10;
  position: absolute; /* Allow precise alignment */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset by half the width for centering */
}

.heroButton.hidden {
  opacity: 0;
  width: 20vh;
  height: 20vh;
  font-size: 10vh;
}

.heroButton {
  width: 13vh; /* Square size for consistency */
  height: 13vh;
  position: absolute;
  border-radius: 50%;
  top: 0;
  background-color: transparent;
  left: 50%; /* Align horizontally with the string */
  transform: translateX(-50%); /* Offset by half the width for centering */

  font-size: 6.5vh;
  color: white; /* Set text color for visibility */
  text-align: center;
  z-index: 20;
  animation: heroSlide 1.25s linear forwards;
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
  transition: transform 0.1s ease, opacity 0.2s ease, font-size 0.15s ease,
    width 0.15s, height 0.15s ease;
  display: -webkit-flex; /* Older versions of Safari */
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -webkit-align-items: center; /* Safari 6.1+ */
  -ms-align-items: center; /* IE 10 */
  align-items: center;
  -webkit-justify-content: center; /* Safari 6.1+ */
  -ms-justify-content: center; /* IE 10 */
  justify-content: center;
}

@keyframes heroSlide {
  0% {
    top: 0%;
  }
  100% {
    top: 100%;
  }
}

.heroContainer {
  height: 85%;
  width: 100%;
  color: black;
  display: flex;
  justify-content: space-evenly;
  position: relative; /* Allow child elements to be positioned absolutely */
  overflow: hidden; /* Hide overflowing elements */
}

.heroCol {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure buttons position correctly within each column */
}

.heroChalBanner {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: var(--titleFont);
  font-size: 6vh;
  padding-top: 5%;
  text-align: center;
}

.colorChalCircleContainer {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.colorChalBanner {
  width: 100%;
  height: 15%;
  justify-content: center;
  align-content: center;
  color: black;
  font-family: var(--titleFont);
  font-size: 6vh;
  padding-top: 5%;
}

.colorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2vh;
}

.colorLabel {
  color: black;
  font-size: 4vh;
  font-family: var(--contentFont);
  height: 10%;
  width: 100%;
}

.colorChalCircle {
  height: 20vh;
  width: 20vh;
  border-radius: 50%;
}

.resultsPrizeContainer {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 2vh;
  font-family: var(--subTitleFont);
  flex-wrap: nowrap;
}

.pickContainer {
  width: 100%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 100%));
  grid-auto-rows: 25%;
  justify-content: center;
  row-gap: 5%;
  margin-top: 10%;
  padding: auto;
}

.firstPage {
  opacity: 0;
  animation: fadeIn 0.3s linear 2s 1 forwards;
}

.voteContent {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 50%));
  grid-auto-rows: 11%;
  justify-content: center;
  row-gap: 2%;
  margin-top: 2%;
  padding: auto;
}

.twoChoiceContent {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 50%));
  grid-auto-rows: 35%;
  justify-content: center;
  row-gap: 2%;
  margin-top: 15%;
  padding: auto;
}

.beautifulSortingContainer {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.spinBottleImage {
  height: 45%;
  width: auto;
  transition: transform 5s ease-in-out;
  margin-bottom: 5vh;
}

.sortingContentWrapper {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sortingChalLabel {
  display: flex;
  width: 100%;
  height: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
  font-family: var(--subTitleFont);
  color: black;
}

.sortingChalOption {
  display: flex;
  width: 100%;
  height: 6vh;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 2.5vh;
  font-family: var(--contentFont);
  border: 5px solid white;
  background-color: var(--offBlack);
  color: white;
  border-radius: 2.5vw;
}

.sortScaleA {
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3.25vh;
  font-family: var(--subTitleFont);
  color: black;
  text-decoration: underline;
  padding-top: 3%;
}
.sortScaleB {
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3.25vh;
  font-family: var(--subTitleFont);
  color: black;
  text-decoration: underline;
  padding-bottom: 3%;
}

.choiceChalContent {
  width: 100%;
  height: 60%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 90%));
  grid-auto-rows: 25%;
  justify-content: center;
  row-gap: 5%;
  margin-top: 5%;
  padding: auto;
}

.choiceChalOption {
  display: flex;
  width: 80%;
  height: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  border: 5px solid black;
  background-color: var(--offBlack);
  color: white;
  border-radius: 2.5vw;
}

.voteOption {
  display: flex;
  width: 90%;
  height: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 2.75vh;
  font-family: var(--contentFont);
  border: 5px solid #252525;
  background-color: var(--offBlack);
  color: white;
  border-radius: 2.5vw;
}
.randomVoteButton {
  grid-column: span 2;
  width: 60%;
  color: white;
  border-radius: 15%/90%;
}

.optionRow {
  display: flex;
  width: 60%;
  height: 80%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
  font-family: var(--subTitleFont);
  border: 3px solid black;
  color: black;
}

.optionRow.highlighted {
  border-color: white;
  box-shadow: 0 0 10px white;
  color: white;
}

.optionRow.selected {
  box-shadow: 0 0 10px #ffff66;
  border-color: #ffff66;
  color: #ffff66;
}

.quizContent {
  width: 100vw;
  height: 40vh;
}

.quizAnswerBig {
  font-size: 2.5vh;
  width: 100%;
  height: auto;
  text-align: left;
}

.quizAnswerMini {
  font-size: 1.75vh;
  width: 100%;
  height: auto;
  text-align: left;
}

.quizAnswersBig {
  width: 100%;
  height: 60%;
  display: grid;
  grid-template-columns: 80%;
  grid-auto-rows: 10%;
  justify-content: center;
  row-gap: 8%;
  overflow-y: hidden;
  padding-top: 5%;
}

.quizAnswersMini {
  width: 100%;
  height: 60%;
  display: grid;
  grid-template-columns: 44% 45%;
  grid-auto-rows: 10%;
  justify-content: center;
  row-gap: 5%;
  overflow-y: hidden;
  padding-top: 5%;
}

.quizRulesText {
  width: 90%;
  height: 40%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  color: black;
  white-space: pre-line;
  opacity: 0;
  animation: zoomText 0.2s linear 0.2s 1 forwards;
  z-index: 2;
  font-size: 3vh;
  margin: auto;
}

.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: var(--baseColor);
}
.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}

.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 1vh;
  left: 0;
  height: 3vh;
  width: 3vh;
  background-color: white;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #151515;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
.checkbox.style-c .checkbox__body {
  color: #151515;
  padding-left: 1vw;
  flex-wrap: nowrap;
}

.rulesText {
  width: 90%;
  height: 75%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  color: black;
  align-content: center;
  white-space: pre-line;
  opacity: 0;
  font-size: 2vh;
  animation: zoomText 0.3s linear 0.2s 1 forwards;
  z-index: 2;
  margin: auto;
}

.horizontalPrompt {
  width: 90%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: black;
}

.rotatedText {
  font-family: var(--subTitleFont);
  font-size: 5.5vh;
  white-space: pre-line;
}

.coinIcon {
  width: 12vh;
  height: 12vh;
}

.coinTextTop {
  text-transform: none !important;
}

.coinTextBot {
  text-transform: none !important;
}

.coinWrapper {
  perspective: 800px;
  -webkit-perspective: 800px;
  height: 60%;
  display: flex;
  align-items: center;
}

#coin {
  position: relative;
  margin: 0 auto;
  color: black;
  width: 30vh;
  height: 30vh;
  font-size: 5.5vh;
}
.coinContainer {
  width: 100%;
  height: 100%;
  font-family: var(--splashTitleFont);
  text-transform: none !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 1vh;
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.side-a {
  border: 3px solid green;
  color: #151515;
  background: radial-gradient(circle, lightgreen, #99ffcc);
}
.side-b {
  color: white;
  background: radial-gradient(circle, #252525, black);
  border: 3px solid #252525;
}

#coin {
  transition: -webkit-transform 1s ease-in;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.side-a {
  z-index: 100;
}
.side-b {
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
}

#coin.heads {
  -webkit-animation: flipHeads 4s ease-out forwards;
  -moz-animation: flipHeads 4s ease-out forwards;
  -o-animation: flipHeads 4s ease-out forwards;
  animation: flipHeads 4s ease-out forwards;
}
#coin.tails {
  -webkit-animation: flipTails 4s ease-out forwards;
  -moz-animation: flipTails 4s ease-out forwards;
  -o-animation: flipTails 4s ease-out forwards;
  animation: flipTails 4s ease-out forwards;
}
@keyframes flipHeads {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(2160deg);
    -moz-transform: rotateY(2160deg);
    transform: rotateY(2160deg);
  }
}

@-webkit-keyframes flipHeads {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(2160deg);
    -moz-transform: rotateY(2160deg);
    transform: rotateY(2160deg);
  }
}

@keyframes flipTails {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(2340deg);
    -moz-transform: rotateY(2340deg);
    transform: rotateY(2340deg);
  }
}
@-webkit-keyframes flipTails {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(2340deg);
    -moz-transform: rotateY(2340deg);
    transform: rotateY(2340deg);
  }
}

.luckyCoinNav {
  width: 40%;
  height: 90%;
  font-family: var(--titleFont);
  font-size: 2.75vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  margin: 3vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  animation: animate-pulse-highlight 3s linear infinite;
}

.luckyCoinNavContainer {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
}

.chanceContent {
  width: 90%;
  height: 50%;
  font-family: var(--subTitleFont);
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  color: black;
}
.chancePicker {
  width: 100%;
  height: 30%;
  margin-bottom: 5%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 4vh;
}

.chanceResult {
  width: 40vw;
  height: 20vh;
  font-family: var(--subTitleFont);
  font-size: 3.5vh;
  border: 5px solid white;
  border-radius: 2.5vw;
  display: flex;
  flex-direction: column;
  text-align: center !important;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.chanceBtn {
  width: 40vw;
  height: 20vh;
  font-family: var(--subTitleFont);
  font-size: 3.5vh;
  animation: animate-pulse-white 2s linear infinite;
  background-color: var(--offBlack);
  border: 5px solid black;
  color: black;
  border-radius: 2.5vw;
  display: flex;
  text-align: center !important;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.chanceBtn span {
  /* Styles for the text within the chanceBtn */
  color: white;
  opacity: 0;
  animation: fadeAndMove 0.1s linear infinite;
}

@keyframes fadeAndMove {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  50% {
    opacity: 1;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(110%);
  }
}

.promptList {
  width: 100%;
  height: 80%;
  margin-top: 5%;
}

.promptListItem {
  width: 100%;
  height: 10%;
  color: black;
  font-size: 2.75vh;
}

.promptRulesText {
  width: 90%;
  height: 40%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  z-index: 2;
  white-space: pre-line;
  color: black;
}

.promptChalText {
  width: 90%;
  height: 30%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-style: italic;
  color: white;
}

.timedChalText {
  width: 90%;
  height: 35%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 12vh;
  color: black;
}

.resultText {
  width: 90%;
  height: 60%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
}

.chalTitleContainer {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 7vh;
  font-family: var(--displayFont);
  text-transform: none !important;
  align-items: center;
  padding-top: 2.5vh;
}
.leaderboardPlayer {
  font-size: 3vh;
  display: flex;
  width: 100%;
  border-bottom: #151515 solid 5px;
}

.playerName {
  text-shadow: 1px 1px 5px black;
}

.leaderboardPlayerName {
  margin-left: 5vw;
}

.leaderboardPlayer span {
  position: absolute;
  font-size: 3vh;
  right: 10px;
  color: var(--accentColor);
}

.leaderboardTitle {
  height: 15%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 9vh;
  opacity: 0;
  font-family: var(--displayFont);
  text-transform: none !important;
  animation: reveal 1s 0.5s forwards;
}
.leaderboardPlayers {
  margin-top: 5vh;
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: (var(--contentFont));
  overflow-y: auto;
}
.leaderboardDisclaimer {
  font-family: (var(--contentFont));
  font-size: 2vh;
  font-style: italic;
  height: 20%;
}

.responseChalContent {
  width: 90%;
  height: 30%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.fullscreenChalContent {
  width: 95%;
  height: 75%;
  font-family: var(--subTitleFont);
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chalInput {
  width: 70%;
  height: 10%;
  margin: 3vh;
  font-family: var(--contentFont);
  font-size: 3vh;
  padding: 1%;
}

.rules {
  font-size: 2.9vh;
  margin-top: 8vh;
  padding: 1%;
}

.ruleEmoji {
  font-size: 12vh;
}

.prompt {
  font-size: 4vh;
}

.wordleBanner {
  width: 100%;
  height: 15%;
  justify-content: center;
  align-content: center;
  color: black;
  font-family: var(--titleFont);
  font-size: 6vh;
  padding-top: 5%;
}

.wordleLetter {
  border: 3px solid black;
  width: 100%;
  height: 100%;
  border-radius: 2.5vw;
  color: black;
  font-size: 4.5vh;
  transition: border-color 0.75s ease, color 0.75s ease,
    background-color 0.75s ease;
  background-color: none;
}

.wordleGrid {
  width: 100%;
  height: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-auto-rows: 17%;
  grid-auto-columns: 13%;
  grid-gap: 3%;
  grid-template-areas:
    "w00 w01 w02 w03"
    "w10 w11 w12 w13"
    "w20 w21 w22 w23"
    "w30 w31 w32 w33"
    "w40 w41 w42 w43";
}
.wordleKey {
  background-color: #151515;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  font-size: 2.5vh;
  width: 10%;
  height: 100%;
  padding-top: 2.5vh;
  border-radius: 2.5vw;
  margin: 0.25%;
}
.keyboardRow {
  display: flex;
  justify-content: center;
}
.wordleKeyboard {
  width: 100%;
  height: 30%;
  display: grid;
}

@keyframes pulseText {
  0% {
    text-shadow: 0 0 5px white;
  }
  50% {
    text-shadow: 0 0 8px white;
  }
  100% {
    text-shadow: 0 0 5px white;
  }
}

@keyframes zoomText {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  40% {
    opacity: 0.75;
    transform: scale(1.05);
  }
  80% {
    opacity: 1;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drink-shaking {
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes driftAndFade0 {
  0% {
    transform: translateY(0vh) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: translateY(-10vh) rotate(-5deg);
  }
  50% {
    transform: translateY(-20vh) rotate(5deg);
  }
  75% {
    transform: translateY(-30vh) rotate(-3deg);
  }
  100% {
    transform: translateY(-40vh) rotate(0deg);
    opacity: 0;
  }
}
@keyframes driftAndFade1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(3vh, -5vh);
  }
}
@keyframes driftAndFade2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-2vh, -5vh);
  }
}
@keyframes driftAndFade3 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(1vh, -5vh);
  }
}

@keyframes fillUpScreen {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes emptyDownScreen {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    height: 0;
    width: 0;
    font-size: 0;
  }
  100% {
    opacity: 1;
    width: 75%;
    height: 15%;
    font-size: 2.5vh;
  }
}

@keyframes flashName {
  0% {
    color: white;
  }
  50% {
    color: var(--turnPlayerColor);
  }
  100% {
    color: white;
  }
}

@keyframes float {
  from {
    transform: rotate(0deg) translate3d(2px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate3d(2px, 0, 0) rotate(-360deg);
  }
}

@keyframes bob1 {
  from {
    transform: rotate(0deg) translate3d(5px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate3d(5px, 0, 0) rotate(-360deg);
  }
}

@keyframes bob2 {
  from {
    transform: rotate(0deg) translate3d(3px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(-360deg) translate3d(3px, 0, 0) rotate(360deg);
  }
}

@keyframes bob3 {
  from {
    transform: rotate(0deg) translate3d(1px, 0, 0) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate3d(1px, 0, 0) rotate(-360deg);
  }
}

/* Selected prize animation */
.prize.selected {
  color: white;
}
